import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import queryString from 'query-string';

import { ApplicationState } from '../../reducers';
import * as navigationActions from '../../actions/app/navigationBar';
import { areas } from '../../constants/ParamountReactConstants';
import { intercomEvent } from '../../utils/IntercomUtils';
import PricingPage from '../../components/body/pricing/PricingPage';
import { fetchPriceSheets, fetchBrandPriceSheets } from '../../actions/parent/price_sheets/fetch';
import { fetchItemPrices } from '../../actions/items/pricing/fetch';
import ItemInfo from '../../components/global/ItemInfo';
import { isReceiver } from '../../utils/UserUtils';
import { getSelectedItems } from '../../selectors/catalogue/catalogueSelector';

const PricingContainer: React.FC = () => {
  const dispatch = useDispatch();

  const {
    brandId,
    itemId,
    selectedItems,
    selectedItem,
    fetchingPriceSheet,
    fetchingBrandPriceSheets,
    itemPriceIsFetching,
    receiver,
  } = useSelector((state: ApplicationState) => {
    return {
      brandId: state.parent.brands.selectedBrandId,
      itemId: state.catalogue.catalogue.selectedItemIds[0],
      selectedItems: state.catalogue.catalogue.selectedItemIds,
      selectedItem: getSelectedItems(state)[0],
      fetchingPriceSheet: state.parent.priceSheets.fetchingPriceSheets,
      fetchingBrandPriceSheets: state.parent.priceSheets.fetchingBrandPriceSheets,
      itemPriceIsFetching: state.items.pricing.itemPriceIsFetching,
      receiver: state.user.user && isReceiver(state.user.user),
    };
  });

  React.useEffect(() => {
    dispatch(navigationActions.setActiveArea(areas.pricing));
  }, [dispatch]);

  React.useEffect(() => {
    intercomEvent('viewed-all-product', {
      location: 'pricing',
      part_number: selectedItem?.part_number,
      brand_code: selectedItem?.brand_code,
    });
  }, [selectedItem]);

  React.useEffect(() => {
    if (itemId) {
      if (receiver) {
        const params = queryString.parse(location.search, { arrayFormat: 'comma' });
        if (params.brandId) {
          dispatch(fetchPriceSheets(Number(params.brandId)));
          dispatch(fetchBrandPriceSheets(Number(params.brandId)));
        }
      }
    }
  }, [brandId, dispatch, itemId, receiver]);

  React.useEffect(() => {
    if (itemId) dispatch(fetchItemPrices(itemId));
  }, [dispatch, itemId]);

  if (selectedItems.length > 1 || selectedItems.length === 0)
    return <ItemInfo items={selectedItems} />;
  if (fetchingPriceSheet || fetchingBrandPriceSheets || itemPriceIsFetching)
    return <Spin className="spinner-center" />;

  return <PricingPage />;
};

export default PricingContainer;
