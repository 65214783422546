import React from 'react';
import { Popover, Button, Form } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PriceSheetType } from '../../../../types/price_sheet';
import { ExtendedResources } from '../../../../types/resources';
import PriceSheetSelect from '../../global/PriceSheetSelect';
import AntTooltip from '../../global/AntTooltip';
import FormMultiSelect from '../../global/Forms/FormMultiSelect';
import { AsyncDispatch } from '../../../../types/global';
import { fetchFilters } from '../../../actions/catalogue/filter/fetch';
import { ApplicationState } from '../../../reducers';
import { getAllCustomFilters } from '../../../selectors/catalogue/filterSelector';
import { booleanToInt, intToBoolean } from '../../../utils/Utils';
import HelpCenter from '../../../containers/HelpCenter';
import { AccountFilter, FilterType } from '../../../../types/filter';

type ReceiverRequestPopoverProps = {
  visible: boolean;
  buttonName: string;
  title: string;
  tooltip?: string;
  hide: () => void;
  open: () => void;
  selectedFilterIds?: number[];
  brandId?: number;
  priceSheets: PriceSheetType[];
  priceTypes: ExtendedResources[];
  defaultSelectedPriceSheetIds?: number[];
  defaultSelectedPriceTypeIds?: number[];
  defaultWithoutPrices?: boolean;
  requestReceiver: (params: {
    priceSheetIds: number[];
    priceTypeIds: number[];
    withoutPrices: boolean;
    filterIds: number[];
  }) => Promise<any>;
  type?: 'primary' | 'text';
};

const ReceiverRequestPopover = ({
  visible,
  buttonName,
  title,
  tooltip,
  defaultSelectedPriceSheetIds,
  defaultSelectedPriceTypeIds,
  defaultWithoutPrices,
  hide,
  open,
  selectedFilterIds,
  brandId,
  priceSheets,
  priceTypes,
  requestReceiver,
  type,
}: ReceiverRequestPopoverProps) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  React.useEffect(() => {
    if (visible && brandId) {
      dispatch(fetchFilters(brandId));
    }
  }, [brandId, dispatch, visible]);

  const { filters, accountFilters, fetchingFilters } = useSelector((state: ApplicationState) => {
    return {
      filters: getAllCustomFilters(state),
      accountFilters: state.catalogue.filter.accountFilters,
      fetchingFilters: state.catalogue.filter.fetchingFilters,
    };
  });

  const getValidationSchema = () => {
    return Yup.object().shape({
      withoutPrices: Yup.number(),
      priceSheetIds: Yup.array().when(
        // only validate if not withoutPrices
        ['withoutPrices'],
        ([withoutPrices], schema) =>
          withoutPrices === 0 ? schema.min(1, t('validation:required')) : schema.min(0)
      ),
      priceTypeIds: Yup.array(),
    });
  };

  const handleRequestChange = (values: {
    priceSheetIds: number[];
    priceTypeIds: number[];
    withoutPrices: number;
    filterIds: number[];
  }) => {
    const { priceSheetIds, priceTypeIds, withoutPrices, filterIds } = values;
    const selectedValues = {
      withoutPrices: intToBoolean(withoutPrices) || false,
      priceSheetIds: !withoutPrices ? priceSheetIds : [],
      priceTypeIds: !withoutPrices ? priceTypeIds : [],
      filterIds,
    };
    return requestReceiver(selectedValues);
  };

  const filterIds = filters.map((f: FilterType) => f.id);
  let filterList = filters;
  if (selectedFilterIds && selectedFilterIds.find(id => !filterIds.includes(id))) {
    const missingFilterIds = selectedFilterIds.filter(id => !filterIds.includes(id));
    const otherUsersFilters = missingFilterIds.map(id => {
      const accFilter = accountFilters.find(f => f.id === id);
      return {
        ...accFilter,
        name: `${accFilter?.name || ''} (${t('common:otherUser')}: ${
          accFilter?.user_first_name.substring(0, 1).toUpperCase() || ''
        }${accFilter?.user_last_name.substring(0, 1).toUpperCase() || ''})`,
      };
    });
    filterList = [...otherUsersFilters, ...filters];
  }

  return (
    <Popover
      trigger="click"
      overlayClassName="channel-table-header__popover"
      placement="left"
      open={visible}
      onOpenChange={visible => {
        if (!visible) hide();
      }}
      content={
        visible && (
          <Formik
            initialValues={{
              withoutPrices: booleanToInt(defaultWithoutPrices) || 0,
              priceSheetIds: defaultSelectedPriceSheetIds || [],
              priceTypeIds: defaultSelectedPriceTypeIds || [],
              filterIds: selectedFilterIds || [],
            }}
            validationSchema={getValidationSchema()}
            onSubmit={(values, { setSubmitting }) => {
              handleRequestChange(values)
                .then(() => setSubmitting(false))
                .catch(() => setSubmitting(false));
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              errors,
              touched,
              dirty,
            }) => (
              <Form layout="vertical">
                <div>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      (errors.priceSheetIds && touched.priceSheetIds && 'error') || undefined
                    }
                    help={(touched.priceSheetIds && errors.priceSheetIds) || undefined}
                  >
                    {brandId && (
                      <div className="flex">
                        <FormMultiSelect
                          className="flex-1"
                          label={t('receiver:productFilter')}
                          name="filterIds"
                          values={filterList}
                          loading={fetchingFilters}
                          getTagTooltip={id => {
                            const filter = filterList.find((f: AccountFilter) => f.id === id);
                            return filter?.user_first_name
                              ? `${filter.user_first_name} ${filter.user_last_name} ${filter.user_email}`
                              : '';
                          }}
                          showTagTooltip
                          allowClear
                        />
                        <HelpCenter
                          className="pl-2 self-center"
                          content={<div>{t('receiver:productFilterInfo')}</div>}
                        />
                      </div>
                    )}

                    <PriceSheetSelect
                      defaultSelectedPriceSheetIds={defaultSelectedPriceSheetIds}
                      defaultSelectedPriceTypeIds={defaultSelectedPriceTypeIds}
                      defaultWithoutPrices={defaultWithoutPrices}
                      priceSheets={priceSheets}
                      priceTypes={priceTypes}
                      updateSelection={({
                        selectedPriceSheetIds,
                        selectedPriceTypeIds,
                        withoutPrices,
                      }: {
                        selectedPriceSheetIds: number[];
                        selectedPriceTypeIds: number[];
                        withoutPrices: number;
                      }) => {
                        if (selectedPriceSheetIds) {
                          setFieldValue('priceSheetIds', selectedPriceSheetIds);
                          setFieldTouched('priceSheetIds');
                        }
                        if (selectedPriceTypeIds)
                          setFieldValue('priceTypeIds', selectedPriceTypeIds);
                        if (withoutPrices) setFieldValue('withoutPrices', 1);
                        else setFieldValue('withoutPrices', 0);
                      }}
                      unboundSheetAndType
                      multiSelectSheets
                      multiSelectTypes
                    />
                  </Form.Item>

                  <div className="flex flex-col items-end">
                    <div>
                      <Button onClick={() => hide()} size="small">
                        {t('common:cancel')}
                      </Button>
                      <Button
                        className="ml-2"
                        type="primary"
                        size="small"
                        onClick={() => handleSubmit()}
                        loading={isSubmitting}
                        disabled={!dirty || isSubmitting}
                      >
                        {t('common:save')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )
      }
      title={title}
    >
      <AntTooltip title={tooltip || ''} hide={!tooltip}>
        <Button type={type || 'primary'} ghost size="small" onClick={() => open()}>
          {buttonName}
        </Button>
      </AntTooltip>
    </Popover>
  );
};

export default ReceiverRequestPopover;
