import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Steps, Button, Form, Spin } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import ExportSelectParts from './ExportSelectParts';
import ExportSelectFormat from './ExportSelectFormat';
import ExportFormatOptions from './ExportFormatOptions';
import { FileExportOption } from '../../../../types/import_export';
import { startNewExport } from '../../../actions/exporter';
import ExportStarted from './ExportStarted';
import ReceiverExportSelectParts from './ReceiverExportSelectParts';
import { fetchExportOptions } from '../../../actions/brand/export/fetch';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';
import { EBP_TYPE_ID } from '../../../constants/ImportExportConstants';

type ExportStepsProps = {
  canManageAdvancedExportOptions: boolean;
  receiver: boolean;
  manufacturer: boolean;
};

const ExportSteps: React.FC<ExportStepsProps> = props => {
  const { canManageAdvancedExportOptions, receiver, manufacturer } = props;
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();
  const [current, setCurrent] = React.useState(0);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const {
    fileExportOptions,
    selectedBrandId,
    fetchingExportOptions,
    segmentList,
    brandCode,
    exportFileTypes,
  } = useSelector((state: ApplicationState) => {
    return {
      fileExportOptions: state.brand.brandExport.fileExportOptions,
      selectedBrandId: state.parent.brands.selectedBrandId,
      fetchingExportOptions: state.brand.brandExport.fetchingExportOptions,
      segmentList: state.resources.data.global.segments,
      brandCode: getSelectedBrandCode(state),
      exportFileTypes: state.brand.brandExport.fileExportOptions as FileExportOption[],
    };
  });

  const [currentFileType, setCurrentFileType] = React.useState<string>('');
  const [currentFileTypeId, setCurrentFileTypeId] = React.useState<number | null>(null);

  React.useEffect(() => {
    setCurrentFileType('');
    setCurrentFileTypeId(null);
  }, [selectedBrandId]);

  const goToNextStep = () => {
    setCurrent(current + 1);
  };

  const sendIntercomEvent = (
    type: string,
    exportSchemaId: number,
    selection: string,
    segments?: number[]
  ) => {
    intercomEvent('viewed-import-export', {
      location: 'export',
      parts: selection,
      db: exportSchemaId === 1 ? 'staging-db' : 'receiver-db',
      format: type,
      segments: segments?.map(id => segmentList.find(s => s.id === id)?.name).join(', ') || '',
      brand_code: brandCode!,
    });
  };

  const getExportFileType = (fileTypeId: number | null) => {
    const findExportOptions = fileExportOptions.find(
      fileTypeOption => fileTypeOption.id === fileTypeId
    );
    return findExportOptions;
  };

  const getAllowedOptions = (fileType?: FileExportOption) => {
    if (fileType && fileType.export_options)
      return canManageAdvancedExportOptions
        ? fileType?.export_options
        : fileType?.export_options.filter(option => option.advanced === 0);
    return [];
  };

  const getExportOptionsSchema = (value: any) => {
    if (typeof value === 'number') return Yup.number().required(t('validation:required'));
    if (typeof value === 'object') {
      const length = value ? value.length - 1 : 0;
      if (length > 0) return Yup.array();
      return Yup.array().nullable().required(t('validation:required'));
    }
    return Yup.string().required(t('validation:required'));
  };

  const schema = () => {
    const fileExportOption: FileExportOption | undefined = getExportFileType(currentFileTypeId);
    const filteredExportOptions = getAllowedOptions(fileExportOption);

    const mandatoryExportOptions = filteredExportOptions?.filter(option => option.mandatory === 1);

    const exportOptionsSchema: any = {};

    mandatoryExportOptions?.forEach(f => {
      exportOptionsSchema[f.code] = Yup.lazy(value => getExportOptionsSchema(value));
    });

    return exportOptionsSchema;
  };

  const getValidationSchema = () => {
    const receiverValidation = {
      receiverExportBrandIds: receiver
        ? Yup.array().of(Yup.number().nullable().required(t('validation:required')))
        : Yup.array().of(Yup.number().nullable().notRequired()),
    };
    return Yup.object().shape({
      ...receiverValidation,
      exportOptions: Yup.object().shape({
        [currentFileType]: Yup.object().shape({
          ...schema(),
        }),
      }),
    });
  };

  const getDescription = (selection: string) => {
    if (selection === 'all') return t('exporter:allParts');
    if (selection === 'populated') return t('exporter:allPopulatedParts');
    if (selection === 'filter') return t('exporter:filter');
  };

  const getOptionsInitValues = (options?: FileExportOption[]) => {
    const initValues: Record<string, Record<string, number[] | null | string>> = {};
    (options || fileExportOptions).forEach(fileType => {
      const optionInitValues: Record<string, any> = {};
      const filteredExportOptions = getAllowedOptions(fileType);

      filteredExportOptions?.forEach(option => {
        if (option.format_type_id === 1 || option.format_type_id === 2)
          optionInitValues[option.code] = option.default_value && Number(option.default_value);
        if (option.format_type_id === 3)
          optionInitValues[option.code] = option.default_value
            ? Number(option.default_value)
            : option.default_value || null;
        if (option.format_type_id === 4) optionInitValues[option.code] = option.default_value || [];
        if (option.format_type_id === 6) optionInitValues[option.code] = option.default_value || [];
        if (option.format_type_id === 5) optionInitValues[option.code] = option.default_value;
      });
      initValues[fileType.name] = optionInitValues;
    });
    return initValues;
  };

  const getSelectPartDescription = ({
    brandIds,
    filterId,
    selection,
  }: {
    brandIds?: number[] | null;
    filterId?: number | null;
    selection?: string;
  }) => {
    if (selection) return `(${getDescription(selection)})`;
    if (brandIds && brandIds.length > 0 && filterId)
      return `(${t('glossary:brand', { count: brandIds.length })} & ${t('exporter:filter')})`;
    if (brandIds && brandIds.length > 0) return t('glossary:brand', { count: brandIds.length });
    return null;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        exportOptions: getOptionsInitValues(),
        filterId: null,
        selection: '',
        populated: 0,
        fileTypeId: null,
        fileTypeName: '',
        advancedOptionsEnabled: false,
        receiverExportBrandIds: [],
        exportSchemaId: manufacturer ? 1 : 2,
      }}
      validationSchema={getValidationSchema()}
      onSubmit={(values, formikActions) => {
        const { setSubmitting } = formikActions;
        setSubmitting(true);

        sendIntercomEvent(
          values.fileTypeName,
          values.exportSchemaId,
          values.selection,
          values.fileTypeId === 10
            ? (values.exportOptions[currentFileType].only_selected_segments as number[])
            : undefined
        );

        const exportFileType: FileExportOption | undefined = getExportFileType(values.fileTypeId!);
        const exportFilterTypeOptions: Record<string, any> = {};
        const keys = Object.keys(values.exportOptions[currentFileType]);

        keys.forEach(key => {
          const option = exportFileType?.export_options.find(option => option.code === key);

          if (option)
            exportFilterTypeOptions[option.id] = values.exportOptions[currentFileType][key];
        });
        if (manufacturer) {
          dispatch(
            // @ts-ignore
            startNewExport({
              brandId: selectedBrandId,
              fileTypeId: values.fileTypeId!,
              fileTypeName: values.fileTypeName!,
              exportSchemaId: values.exportSchemaId,
              populated: values.populated,
              filterId: values.filterId,
              exportFilterTypeOptions,
            })
          );
        }
        if (receiver) {
          dispatch(
            // @ts-ignore
            startNewExport({
              receiverExportBrandIds: values.receiverExportBrandIds,
              fileTypeId: values.fileTypeId!,
              fileTypeName: values.fileTypeName!,
              exportSchemaId: 2, // for receiver db
              filterId: values.filterId,
              exportFilterTypeOptions,
            })
          );
        }
        goToNextStep();
        setSubmitting(false);
      }}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => {
        const goToPrev = () => {
          const step = current - 1;
          setCurrent(step);
        };

        const getOptionsText = () => {
          if (currentFileType) {
            if (values.advancedOptionsEnabled) return `(${t('exporter:advanceOptionsSelected')})`;
            return `(${t('exporter:exportOptionsSelected')})`;
          }
        };

        const setEBPexport = () => {
          const filteType = exportFileTypes.find(type => type.id === EBP_TYPE_ID);
          setFieldValue('fileTypeId', EBP_TYPE_ID);
          setFieldValue('fileTypeName', filteType?.name);
          setCurrentFileType(filteType?.name || '');
          setCurrentFileTypeId(EBP_TYPE_ID);
        };

        const steps = [
          {
            title: t('exporter:selectParts'),
            content: receiver ? (
              <ReceiverExportSelectParts
                handleSelectBrands={values => {
                  setFieldValue('receiverExportBrandIds', values);
                  if (values.length > 1) setEBPexport();
                }}
              />
            ) : (
              <ExportSelectParts handleCurrentStep={() => setCurrent(current + 1)} />
            ),
            description: receiver
              ? getSelectPartDescription({
                  brandIds: values.receiverExportBrandIds,
                  filterId: values.filterId,
                })
              : getSelectPartDescription({ selection: values.selection }),
          },
          {
            title: t('exporter:selectFormat'),
            content: (
              <ExportSelectFormat
                multipleBrands={values.receiverExportBrandIds.length > 1}
                handleCurrentStep={(fileTypeId, fileType) => {
                  setCurrent(current + 1);
                  setCurrentFileType(fileType);
                  setCurrentFileTypeId(fileTypeId);
                }}
              />
            ),
            description: values.fileTypeName ? `(${values.fileTypeName})` : null,
          },
          {
            title: t('exporter:formatOptions'),
            content: (
              <Form {...formItemLayout} labelAlign="left">
                <ExportFormatOptions
                  segment="export"
                  fileTypeId={values.fileTypeId!}
                  fileTypeName={values.fileTypeName!}
                  canManageAdvancedExportOptions={canManageAdvancedExportOptions}
                  exportOptions={getExportFileType(values.fileTypeId!)?.export_options || []}
                  handleAdvancedOptions={advancedOptionsEnabled => {
                    setFieldValue('advancedOptionsEnabled', advancedOptionsEnabled);
                  }}
                />
              </Form>
            ),
            description: getOptionsText(),
          },
          {
            title: t('exporter:exportStarted'),
            content: <ExportStarted />,
          },
        ];

        const handleDisable = () => {
          if (manufacturer) {
            if (current === 0 && !values.selection && values.selection !== 'filter') return true;
            if (values.selection === 'filter' && !values.filterId) return true;
            if (current === 1 && !values.fileTypeId) return true;
          }
          if (receiver) {
            if (current === 0 && values.receiverExportBrandIds.length === 0) return true;
            if (current === 1 && !values.fileTypeId) return true;
          }
          return false;
        };

        const handleStepChange = (step: number) => {
          if (manufacturer) {
            if (step < current) setCurrent(step);
            else if (step === 1 && values.selection) setCurrent(step);
            else if (step === 2 && values.fileTypeId) setCurrent(step);
            else if (step === 3) handleSubmit();
          }
          if (receiver) {
            if (step < current) setCurrent(step);
            else if (step === 1 && values.receiverExportBrandIds.length > 0) {
              setCurrent(step);
              dispatch(fetchExportOptions('export', values.receiverExportBrandIds)).then(result => {
                setValues({ ...values, exportOptions: getOptionsInitValues(result.value.data) });
              });
            } else if (step === 2 && values.fileTypeId) setCurrent(step);
            else if (step === 3) handleSubmit();
          }
        };

        const items = steps.map(item => ({
          key: item.title,
          title: item.title,
          description: item.description,
        }));

        if (fetchingExportOptions) return <Spin className="spinner-center mt-2" />;

        return (
          <div className="export-steps__form">
            <Steps
              current={current}
              onChange={handleStepChange}
              className="export-steps"
              items={items}
            />

            <div className="flex-1 flex flex-col overflow-hidden">
              <div className="steps-content flex-1 overflow-x-hidden overflow-y-auto pr-6">
                {steps[current].content}
              </div>

              <div className="flex pt-1">
                {current > 0 && (
                  <Button style={{ margin: '0 8px' }} onClick={() => goToPrev()}>
                    {t('exporter:back')}
                  </Button>
                )}
                {current < steps.length - 2 && (
                  <Button
                    type="primary"
                    onClick={() => handleStepChange(current + 1)}
                    disabled={handleDisable()}
                  >
                    {t('exporter:next')}
                  </Button>
                )}
                {current === steps.length - 2 && (
                  <Button type="primary" onClick={() => handleSubmit()}>
                    {t('common:export')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ExportSteps;
