import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

import { selectBrand, setBrandInitializeStatus } from './update';
import { resetExportBuilderAdvanced } from '../export_builder_advanced/update';
import catalogueActions from '../../catalogue/catalogue/index.ts';
import filterActions from '../../catalogue/filter';
import { fetchSyncLog, fetchOutOfSyncItemsCount } from '../synchronisation/index.ts';
import { fetchSettingsCustomFields } from '../custom_fields';
import { fetchAnalysesCount } from '../analyses/fetch';
import { fetchBrandResources } from '../resources';
import { resetAllApplications } from '../../all_applications/update';
import { fetchVcdbVersions } from '../../items/application/fetch';
import { fetchPriceSheets, fetchBrandPriceSheets } from '../../parent/price_sheets/fetch';

const CancelToken = axios.CancelToken;

let fetchSubBrandsCancelToken;

export function fetchSubBrands(brandId) {
  if (fetchSubBrandsCancelToken) fetchSubBrandsCancelToken();

  const params = utils.createURL([{ name: 'external_brand_ids', values: brandId }]);

  return {
    type: 'FETCH_SUB_BRANDS',
    payload: axios.get(`${getApiRoot()}/parents/owners/brands/sub_brands${params}`, {
      cancelToken: new CancelToken(c => {
        fetchSubBrandsCancelToken = c;
      }),
    }),
  };
}

let fetchAvailableChAndReCancelToken;

export function fetchAvailableChannelAndReceivers(brandIds) {
  if (fetchAvailableChAndReCancelToken) fetchAvailableChAndReCancelToken();

  const params = utils.createURL([{ name: 'brand_ids', values: brandIds && brandIds.join(',') }]);

  return {
    type: '@MANUFACTURER/FETCH_AVAILABLE_CHANNELS_AND_RECEIVERS',
    payload: axios.get(`${getApiRoot()}/available_channels${params}`, {
      cancelToken: new CancelToken(c => {
        fetchAvailableChAndReCancelToken = c;
      }),
    }),
  };
}

export function initializeBrand(brandId) {
  return dispatch => {
    dispatch(setBrandInitializeStatus(false));
    dispatch(catalogueActions.resetCatalogue());
    dispatch(filterActions.setFilter({}));
    dispatch(filterActions.resetClientFilterParams());
    dispatch(resetExportBuilderAdvanced());
    dispatch(resetAllApplications());
    dispatch(fetchVcdbVersions());
    // fetches not needed immediately
    dispatch(fetchAnalysesCount(brandId));
    dispatch(fetchOutOfSyncItemsCount(brandId));
    dispatch(fetchPriceSheets(brandId));
    dispatch(fetchBrandPriceSheets(brandId));
    Promise.all([
      dispatch(selectBrand(brandId)),
      dispatch(fetchSubBrands(brandId)),
      dispatch(fetchAvailableChannelAndReceivers([brandId])),
      dispatch(catalogueActions.fetchBrandItemTags(brandId)),
      dispatch(catalogueActions.fetchItemsSummary(brandId)),
      dispatch(fetchBrandResources(brandId)),
      dispatch(fetchSyncLog(brandId)),
      dispatch(fetchSettingsCustomFields(brandId)),
    ]).then(() => {
      dispatch(setBrandInitializeStatus(true));
    });
  };
}
