import React, { useCallback } from 'react';
import {
  AutoSizer,
  Table,
  Column,
  CellMeasurer,
  CellMeasurerCache,
  TableCellProps,
} from 'react-virtualized';
import { Select, Checkbox, Alert, Form, Button, Input } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';
import { get as _get } from 'lodash';
import PageFormik from '../../global/page/PageFormik';
import InputWithPreview from '../../global/InputWithPreview';
import SelectWithPreview from '../../global/SelectWithPreview';
import {
  MappedItemCustomFields,
  CustomFieldType,
  CustomFieldOption,
} from '../../../../types/custom_fields';
import Page from '../../global/page/Page';
import { typingDone, validUrlRegExp } from '../../../utils/Utils';
import externalLink from '../../../../images/icons/external-link.svg';
import JoditHtmlEditor from '../../../containers/settings/JoditHtmlEditor';

const { Option } = Select;

type CustomFieldsItemTableProps = {
  fetchingData: boolean;
  customFields: MappedItemCustomFields[];
  brandId: number;
  updateCustomFields: (values: any) => Promise<any>;
  runScript: (scriptId: number) => Promise<any>;
};

const cache: CellMeasurerCache = new CellMeasurerCache({ minHeight: 48, fixedWidth: true });

const CustomFieldsItemTable: React.FC<CustomFieldsItemTableProps> = ({
  fetchingData,
  customFields,
  updateCustomFields,
  runScript,
  brandId,
}) => {
  const { t } = useTranslation();
  const tableRef = React.useRef<any>(null);

  const [runningScriptIds, setRunningScriptIds] = React.useState<number[]>([]);
  const [keywords, setKeywords] = React.useState('');
  const [customFieldsData, setCustomFieldsData] = React.useState(customFields || []);

  const addRunningId = (id: number) => setRunningScriptIds([...runningScriptIds, id]);
  const removeRunningId = (id: number) =>
    setRunningScriptIds(runningScriptIds => runningScriptIds.filter(rId => rId !== id));

  const handleResize = useCallback(() => {
    if (brandId) cache.clearAll();
  }, [brandId]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  React.useEffect(() => {
    cache.clearAll();
  }, [customFields]);

  const handleKeywordSearch = (filterKeywords: string) => {
    setKeywords(filterKeywords);
    setCustomFieldsData(
      customFields.filter(({ name }) => name.toLowerCase().includes(filterKeywords.toLowerCase()))
    );
    handleResize();
  };

  const getValidation = () => {
    return Yup.object().shape({
      customFields: Yup.array().of(
        Yup.object().shape({
          custom_field_type_id: Yup.number(),
          value: Yup.string().when('custom_field_type_id', {
            is: (value: number) => value === CustomFieldType.Link,
            then: schema => schema.matches(validUrlRegExp, t('validation:enterValidUrl')),
          }),
        })
      ),
    });
  };

  return customFields.length === 0 ? (
    <Page showExpandButton>
      <Alert
        message={t('segments:customFields.infoTitle')}
        description={
          <div>
            <Trans i18nKey="segments:customFields.goToSettings">
              Go to{' '}
              <Link
                to={{
                  pathname: brandId ? '/brand/settings/custom-fields' : '/settings/custom-fields',
                  search: brandId ? `brandId=${brandId}` : '',
                }}
              >
                Settings
              </Link>
              for adding and configuring your custom fields.
            </Trans>
          </div>
        }
        type="info"
      />
    </Page>
  ) : (
    <PageFormik
      initialValues={{ customFields }}
      fetchingData={fetchingData}
      renderHeader={() => (
        <div className="flex flex-1 ml-1">
          <Input.Search
            className="cfs__item-top-bar-search"
            value={keywords}
            onChange={e => handleKeywordSearch(e.target.value)}
            placeholder={t('settings:customFields.searchCustomFields')}
            size="small"
            allowClear
          />
        </div>
      )}
      onSubmit={(values, { setSubmitPending, setSubmitSuccess, setSubmitError }) => {
        setSubmitPending();
        updateCustomFields(values)
          .then(() => setSubmitSuccess())
          .catch(() => setSubmitError());
      }}
      contentNoSpacing
      contentNoScroll
      showExpandButton
      validationSchema={getValidation()}
    >
      {({ setFieldValue, setFieldTouched, validateForm, values, dirty, errors, touched }) => {
        const columnCellRenderer = ({ dataKey, parent, rowIndex, rowData }: TableCellProps) => {
          const fullDataRowIndex = values.customFields.findIndex(
            (f: MappedItemCustomFields) => f.id === rowData.id
          );

          const formField = values.customFields[fullDataRowIndex];
          const value = formField.value;
          const cutomFieldTypeId = formField.custom_field_type_id;
          const formikIndex = `customFields[${fullDataRowIndex}].value`;

          return (
            <CellMeasurer
              cache={cache}
              columnIndex={0}
              key={dataKey}
              parent={parent}
              rowIndex={rowIndex}
            >
              {cutomFieldTypeId === CustomFieldType.Input && (
                <InputWithPreview
                  value={value}
                  onChange={e => setFieldValue(formikIndex, e.target.value)}
                  showCount
                />
              )}
              {cutomFieldTypeId === CustomFieldType.Number && (
                <InputWithPreview
                  type="number"
                  value={value}
                  onChange={e => setFieldValue(formikIndex, e.target.value)}
                  showCount
                />
              )}
              {cutomFieldTypeId === CustomFieldType.Dropdown && (
                <SelectWithPreview
                  value={value}
                  onChange={(val: string | undefined) => setFieldValue(formikIndex, val || '')}
                  allowClear
                >
                  {rowData.options.map((option: CustomFieldOption) => {
                    return (
                      <Option key={option.id} value={option.name}>
                        {option.name}
                      </Option>
                    );
                  })}
                </SelectWithPreview>
              )}
              {cutomFieldTypeId === CustomFieldType.Checkbox && (
                <Checkbox
                  style={{ marginLeft: 12 }}
                  checked={value === '1'}
                  onChange={e => setFieldValue(formikIndex, e.target.checked ? '1' : '')}
                />
              )}
              {cutomFieldTypeId === CustomFieldType.Text_Editor && (
                <JoditHtmlEditor
                  htmlText={value}
                  dirty={dirty}
                  handleOnChange={html => {
                    setFieldValue(formikIndex, html);
                  }}
                />
              )}

              {cutomFieldTypeId === CustomFieldType.Link && (
                //  use lodash because name can include dots eg. foo.bar
                <div className="flex flex-row mt-px">
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      _get(errors, formikIndex) && _get(touched, formikIndex) ? 'error' : ''
                    }
                    help={(_get(touched, formikIndex) && _get(errors, formikIndex)) || undefined}
                    className="flex-1"
                  >
                    <InputWithPreview
                      placeholder="https://"
                      value={value}
                      onChange={e => {
                        setFieldValue(formikIndex, e.target.value);
                        typingDone(() => {
                          handleResize();
                          tableRef?.current?.forceUpdateGrid();
                        });
                      }}
                      displayError={!!(_get(errors, formikIndex) && _get(touched, formikIndex))}
                      onBlur={() => {
                        setFieldTouched(formikIndex);
                        validateForm().then(() => {
                          handleResize();
                          tableRef?.current?.forceUpdateGrid();
                        });
                      }}
                      showCount
                    />
                  </Form.Item>
                  {validUrlRegExp.test(value) && (
                    <Button
                      className="cfs__external-link ml-1"
                      type="link"
                      href={value.startsWith('http') ? value : `//${value}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      icon={<img src={externalLink} alt="" />}
                    />
                  )}
                </div>
              )}
              {cutomFieldTypeId === CustomFieldType.Button && (
                <Button
                  onClick={async () => {
                    addRunningId(rowData.id);
                    await runScript(rowData.script_id);
                    removeRunningId(rowData.id);
                  }}
                  loading={runningScriptIds.includes(rowData.id)}
                >
                  {t('api:runScript')}
                </Button>
              )}
            </CellMeasurer>
          );
        };

        return (
          <AutoSizer key={customFieldsData.length}>
            {({ width, height }) => (
              <Table
                ref={tableRef}
                width={width}
                height={height}
                headerHeight={48}
                rowHeight={cache.rowHeight}
                rowCount={customFieldsData.length}
                rowGetter={({ index }) => {
                  return customFieldsData[index];
                }}
                className="cfs_item-table"
                deferredMeasurementCache={cache}
                data={customFieldsData}
              >
                <Column label={t('common:name')} dataKey="name" width={width / 4} />
                <Column
                  width={(width / 4) * 3}
                  style={{ overflow: 'visible', marginRight: '40px' }}
                  label={t('common:value')}
                  headerStyle={{ marginLeft: 12 }}
                  dataKey="values"
                  cellRenderer={columnCellRenderer}
                />
              </Table>
            )}
          </AutoSizer>
        );
      }}
    </PageFormik>
  );
};

export default CustomFieldsItemTable;
