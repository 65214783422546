import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
  SetFilterValuesFuncParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AutoResizer } from 'react-base-table';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ApplicationElement } from '../../../../types/all_application_validation';
import { StandardResource, Vehicle } from '../../../../types/resources';
import resourcesMap from '../../../constants/ApplicationResourcesMapping.json';
import flatGridConfigs from '../../../constants/ApplicationFlatGridConfigs.json';
import flatGridEquipmentConfigs from '../../../constants/ApplicationFlatGridEquipmentConfigs.json';
import { Application, ApplicationType, RankedApplications } from '../../../../types/application';
import { AsyncDispatch } from '../../../../types/global';
import {
  convertConfigIdsNameToName,
  convertConfigNameToIds,
} from '../../../utils/ApplicationUtils';
import YearRangeFilter from './YearRangeFilter';
import { fetchGridPreview } from '../../../actions/items/application/fetch';
import { mapApplicationResourcesGridGroupData } from '../../../selectors/all_applications/allApplicationsSelector';
import YearRangeFloatingFilter from './YearRangeFloatingFilter';

const resourcesMapping = resourcesMap as { [key: string]: string };
const configNames = flatGridConfigs as { [key: string]: string };
const equipmentConfigNames = flatGridEquipmentConfigs as { [key: string]: string };

let validResources: RankedApplications = {} as RankedApplications;

type ApplicationAGTEditProps = {
  applicationType?: ApplicationType;
  application: Application;
  selectedItemId: number;
  selectedVcdbs: number[];
  rankedApplications: any;
  vehicleResources: Vehicle;
  getRecommendations: boolean;
  setRecommendations: (status: boolean) => Promise<any>;
  updateApplication: (
    application: Omit<Application, 'item_application_id'>,
    configName: string,
    filterModel: any
  ) => void;
};

const ApplicationAGTEdit: React.FC<ApplicationAGTEditProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    application,
    applicationType,
    selectedItemId,
    selectedVcdbs,
    rankedApplications,
    vehicleResources,
    getRecommendations,
    setRecommendations,
  } = props;

  const gridRef: any = useRef();

  React.useEffect(() => {
    gridRef.current?.api.refreshServerSide({ purge: true });
  }, [selectedVcdbs]);

  const allSubconfigValues = useCallback(
    (configName: string) => {
      // get all values to a specific config name e.g. position
      // resourcesMapping contains name mapping e.g. engine_mfrs => mfrs, transmission_mfrs => mfrs
      if (resourcesMapping.hasOwnProperty(configName)) {
        configName = resourcesMapping[configName];
      }

      return vehicleResources && vehicleResources[configName] ? vehicleResources[configName] : [];
    },
    [vehicleResources]
  );

  const allValidSubconfigValues = useCallback((configName: string) => {
    if (validResources?.hasOwnProperty(configName)) {
      return validResources[configName];
    }

    return [];
  }, []);

  const [filterModel, setFilterModel] = useState<{ [key: string]: any }>({});
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);

  const getFilterValues = useCallback(
    (params: SetFilterValuesFuncParams) => {
      const selectedColumn = params.colDef.field;

      let values =
        validResources && validResources.hasOwnProperty(selectedColumn || '')
          ? allValidSubconfigValues(selectedColumn || '')
          : allSubconfigValues(selectedColumn || '');

      if ((selectedColumn === 'makes' || selectedColumn === 'mfrs') && values.length === 0)
        values = allSubconfigValues(selectedColumn);

      params.success(values.map((v: { name: any }) => v.name));
    },
    [allValidSubconfigValues, allSubconfigValues]
  );

  const getYearFilterValues = useCallback(() => {
    const values = validResources?.hasOwnProperty('years')
      ? allValidSubconfigValues('years')
      : allSubconfigValues('years');

    return values;
  }, [allValidSubconfigValues, allSubconfigValues]);

  const [columnDefs, setColumnDef] = useState<ColDef[]>(
    Object.keys(
      applicationType === ApplicationType.VEHICLE ? flatGridConfigs : flatGridEquipmentConfigs
    ).map(c => {
      if (typeof c === 'string') {
        const columnHasSelection = filterModel?.hasOwnProperty(c);
        const pinnedCols =
          applicationType === ApplicationType.VEHICLE
            ? ['makes', 'models', 'years', 'sub_models', 'regions']
            : ['mfrs', 'equipment_models', 'vehicle_types', 'years', 'regions'];
        return {
          headerName:
            applicationType === ApplicationType.VEHICLE ? configNames[c] : equipmentConfigNames[c],
          field: c,
          pinned: pinnedCols.includes(c) ? 'left' : undefined,
          filter: c === 'years' ? YearRangeFilter : undefined,
          floatingFilterComponent: c === 'years' ? YearRangeFloatingFilter : undefined,
          filterParams: {
            defaultToNothingSelected: true,
            values: c === 'years' ? getYearFilterValues : getFilterValues,
            refreshValuesOnOpen: true,
          },
        };
      }
      // todo: check
      return { field: '' };
    })
  );

  React.useEffect(() => {
    const pinnedCols =
      applicationType === ApplicationType.VEHICLE
        ? ['makes', 'models', 'years', 'sub_models', 'regions']
        : ['mfrs', 'equipment_models', 'vehicle_types', 'years', 'regions'];

    setColumnDef(
      Object.keys(
        applicationType === ApplicationType.VEHICLE ? flatGridConfigs : flatGridEquipmentConfigs
      ).map(c => {
        if (typeof c === 'string') {
          const columnHasSelection = filterModel.hasOwnProperty(c);

          return {
            headerName:
              applicationType === ApplicationType.VEHICLE
                ? configNames[c]
                : equipmentConfigNames[c],
            field: c,
            pinned: pinnedCols.includes(c) ? 'left' : undefined,
            filter: c === 'years' ? YearRangeFilter : undefined,
            floatingFilterComponent: c === 'years' ? YearRangeFloatingFilter : undefined,
            filterParams: {
              defaultToNothingSelected: true,
              values: c === 'years' ? getYearFilterValues : getFilterValues,
              refreshValuesOnOpen: true,
            },
          };
        }
        // todo: check
        return { field: '' };
      })
    );
  }, [getFilterValues, getYearFilterValues, filterModel, applicationType]);

  React.useEffect(() => {
    if (rankedApplications && rankedApplications.years)
      validResources = {
        ...rankedApplications,
        years: rankedApplications.years.map((year: { id: number }) => ({ ...year, name: year.id })),
      };
    else validResources = rankedApplications;
  }, [rankedApplications]);

  const convertApplicationToFilterModel = useCallback(
    (appl: Application) => {
      const newFilterModel: { [key: string]: any } = {};

      Object.keys(appl).forEach(key => {
        if (
          (key.includes('_ids') || key === 'years') &&
          !key.startsWith('item_') &&
          key !== 'application_category_ids' &&
          key !== 'vehicle_group_ids' &&
          key !== 'equipment_base_ids'
        ) {
          const configName = convertConfigIdsNameToName(key);
          const allValues = allSubconfigValues(configName);
          newFilterModel[configName] = {
            values: appl[key]
              .map((id: number) =>
                allValues.find((config: StandardResource) => config.id === id)?.name.toString()
              )
              .filter(Boolean),
            filterType: 'set',
          };
        }
      });

      return newFilterModel;
    },
    [allSubconfigValues]
  );

  const convertFilterModelToApplication = (filterModel: { [key: string]: any }) => {
    const application: ApplicationElement = {} as ApplicationElement;
    Object.keys(filterModel).forEach(key => {
      const values = filterModel[key].values;
      const valueIds = values.map((valueName: string) => {
        return allSubconfigValues(key).find((config: StandardResource) => {
          // years are numbers
          return config.name.toString() === valueName;
        }).id;
      });

      application[convertConfigNameToIds(key)] = valueIds;
    });

    return application;
  };

  const convertFilterModelForRanks = (filterModel: { [key: string]: any }) => {
    const application: ApplicationElement = {} as ApplicationElement;
    Object.keys(filterModel).forEach(key => {
      const values = filterModel[key].values;

      application[convertConfigNameToIds(key)] = values;
    });

    return application;
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      enableRowGroup: true,
      minWidth: 120,
    }),
    []
  );

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnExpandAll: true,
          },
        },
        'filters',
      ],
      defaultToolPanel: '',
    }),
    []
  );

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      const applicationFilterModel = convertApplicationToFilterModel(application);
      e.api.setFilterModel(applicationFilterModel);
      setFilterModel({ ...applicationFilterModel });

      const dataSource: IServerSideDatasource = {
        getRows: params => {
          const page = (params.request.endRow || 100) / 100;
          const filterModel = params.api.getFilterModel();
          const namedMappedApplication = convertFilterModelForRanks(filterModel);
          const sortModel = params.request.sortModel[0];

          if (Object.keys(filterModel).length === 0) params.success({ rowData: [] });
          else
            dispatch(
              fetchGridPreview(
                namedMappedApplication,
                selectedItemId,
                params.context.selectedVcdbs,
                applicationType === ApplicationType.EQUIPMENT,
                sortModel?.colId,
                sortModel?.sort,
                page
              )
            ).then(response => {
              const rows = response.value.data.applications || response.value.data;

              const mappedRows = mapApplicationResourcesGridGroupData(rows, vehicleResources);

              setRowCount(response.value.data.total_count);
              params.success({ rowData: mappedRows });
            });
        },
      };
      e.api.setServerSideDatasource(dataSource);
    },
    [
      application,
      applicationType,
      convertApplicationToFilterModel,
      dispatch,
      selectedItemId,
      vehicleResources,
    ]
  );

  return (
    <AutoResizer>
      {resProps => (
        <div
          style={{
            height: resProps.height ? resProps.height - 40 : undefined,
            width: resProps.width ? resProps.width : undefined,
          }}
        >
          <div className="ag-theme-alpine h-full">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowSelection="multiple"
              rowModelType="serverSide"
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              showOpenedGroup
              onGridReady={onGridReady}
              cacheBlockSize={100}
              cacheOverflowSize={2}
              maxConcurrentDatasourceRequests={2}
              infiniteInitialRowCount={100}
              suppressRowClickSelection
              onFilterChanged={e => {
                let changedFilterModel = e.api.getFilterModel();
                if (filterModel.makes && filterModel.models) {
                  if (!getRecommendations) setRecommendations(true);
                } else if (getRecommendations) setRecommendations(false);

                const configName = e.columns[0].getColId();

                // reset all filters on make change
                if (
                  filterModel.makes?.values.length > 0 &&
                  filterModel.makes?.values.toString() !==
                    changedFilterModel.makes?.values.toString()
                ) {
                  const values = changedFilterModel.makes?.values;
                  changedFilterModel = changedFilterModel.makes
                    ? {
                        makes: { ...changedFilterModel.makes, values: [values[values.length - 1]] },
                      }
                    : {};
                  e.api.setFilterModel(changedFilterModel);
                }
                // reset all filters on mfr change
                if (
                  filterModel.mfrs?.values.length > 0 &&
                  filterModel.mfrs?.values.toString() !== changedFilterModel.mfrs?.values.toString()
                ) {
                  const values = changedFilterModel.mfrs?.values;
                  changedFilterModel = changedFilterModel.mfrs
                    ? { mfrs: { ...changedFilterModel.mfrs, values: [values[values.length - 1]] } }
                    : {};
                  e.api.setFilterModel(changedFilterModel);
                }

                setFilterModel({ ...changedFilterModel });

                props.updateApplication(
                  convertFilterModelToApplication(changedFilterModel),
                  configName,
                  convertFilterModelForRanks(changedFilterModel)
                );
              }}
              onFilterModified={e => {}}
              context={{ rankedApplications, selectedVcdbs }}
              reactiveCustomComponents
            />
          </div>
          <div>{`${t('application:applicationCount')} ${rowCount || ''}`}</div>
        </div>
      )}
    </AutoResizer>
  );
};

export default ApplicationAGTEdit;
