import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Switch, Table, Popconfirm } from 'antd';
import { FormikValues } from 'formik';
import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AutoSizer } from 'react-virtualized';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { ApplicationState } from '../../../reducers';
import { mapPricesToPriceSheet } from '../../../selectors/pricing/pricingSelector';
import PageFormik, { CustomFormikActions } from '../../global/page/PageFormik';
import {
  CombinedItemPriceSheet,
  UpdateItemPrice,
  InitItemPrice,
  PriceSheet,
} from '../../../../types/price_sheet';
import { AnalysisType, ExtendedResources } from '../../../../types/resources';
import { usedIds, unUsedPriceTypes, addMinTwoDecimals } from './PriceSheetUtils';
import PriceItemTable from './PriceItemTable';
import AntTooltip from '../../global/AntTooltip';
import { updateItemPrices } from '../../../actions/items/pricing/update';
import { AsyncDispatch } from '../../../../types/global';
import { DefaultValue } from '../../../../types/brand_settings';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import IntercomFormEventHandler from '../../global/page/IntercomFormEventHandler';
import { hasPermission } from '../../../utils/Permissions';
import { isReceiver } from '../../../utils/UserUtils';
import { Analysis } from '../../../../types/analyses';
import { extendedAnalysesBySegment } from '../../../selectors/item_analysis/itemAnalysisSelector';
import AnalysesAlertIcon from '../../global/AnalysesAlertIcon';

const PricingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItem,
    itemPricesMappedToPriceSheet,
    resources,
    brandPriceSheetIds,
    defaultValues,
    itemId,
    brandId,
    canMaintainProduct,
    receiver,
    analysesBySegment,
  } = useSelector((state: ApplicationState) => ({
    selectedItem: getSelectedItems(state)[0],
    brandId: state.parent.brands.selectedBrandId,
    resources: state.resources.data.price,
    itemPricesMappedToPriceSheet: mapPricesToPriceSheet(state),
    brandPriceSheetIds: state.parent.priceSheets.brandPriceSheetIds,
    defaultValues: state.settings.defaultValues,
    itemId: state.catalogue.catalogue.selectedItemIds[0],
    canMaintainProduct: hasPermission(state.user.user, 'can_maintain_products'),
    receiver: state.user.user && isReceiver(state.user.user),
    analysesBySegment: extendedAnalysesBySegment(state),
  }));

  const [showAllPriceSheets, setShowAllPriceSheets] = React.useState<boolean>(false);

  const getActivePriceSheets = () => {
    return itemPricesMappedToPriceSheet.filter((priceSheet: CombinedItemPriceSheet) => {
      return (
        (brandPriceSheetIds.includes(priceSheet.id) && priceSheet.used === 1) ||
        (receiver &&
          brandPriceSheetIds.includes(priceSheet.id) &&
          priceSheet.item_prices.length > 0)
      );
    });
  };

  const [expandedKeys] = React.useState<number[]>(
    !showAllPriceSheets ? getActivePriceSheets().map((priceSheet: PriceSheet) => priceSheet.id) : []
  );

  const editShowPriceSheetsRow = () => (
    <div className="px-2 pt-2 flex justify-between items-center">
      <Popconfirm
        title={t('pricing:confirmSwitchToPriceSheetSettings')}
        okText={t('common:yes')}
        cancelText={t('common:no')}
        onConfirm={() => navigate(`/brand/settings/price-sheets?brandId=${brandId}`)}
      >
        <Button size="small" style={{ width: '180px' }} data-testid="edit-price-sheet">
          {t('pricing:editPriceSheets')}
        </Button>
      </Popconfirm>
      <div className="flex justify-end leading-10">
        <span className="text-gray-800 pr-1">{t('pricing:showallPriceSheets')}</span>

        <AntTooltip title={t('pricing:showAllInfoIcon')} className="self-center pr-2">
          <InfoCircleOutlined className="ant-info-tooltip" />
        </AntTooltip>

        <span className="pr-2">
          <Switch
            checked={showAllPriceSheets}
            onChange={() => setShowAllPriceSheets(!showAllPriceSheets)}
            size="small"
          />
        </span>
      </div>
    </div>
  );

  const columns = [
    {
      title: t('pricing:number'),
      key: 'number',
      ellipsis: true,
      render: (record: CombinedItemPriceSheet) => {
        return (
          <React.Fragment>
            <AnalysesAlertIcon analyses={record.analyses} className="mr-2" />
            {record.number}
          </React.Fragment>
        );
      },
    },
    {
      title: t('pricing:supersededNumber'),
      dataIndex: 'superseded_price_sheet_number',
      key: 'superseded_price_sheet_number',
      ellipsis: true,
    },
    { title: t('pricing:name'), dataIndex: 'name', key: 'name', ellipsis: true },
    {
      title: t('pricing:currency'),
      key: 'currency_id',
      ellipsis: true,
      render: (record: CombinedItemPriceSheet) => {
        const existingValue = resources.currencies.find(
          (currency: ExtendedResources) => currency.id === record?.currency_id
        );
        return existingValue?.name;
      },
    },
    {
      title: t('pricing:effectiveDate'),
      dataIndex: 'effective_date',
      key: 'effective_date',
      ellipsis: true,
      render: (effectiveDate: string) => {
        return effectiveDate ? moment(effectiveDate).format('MM-DD-YYYY') : null;
      },
    },
    {
      title: t('pricing:expirationDate'),
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      ellipsis: true,
      render: (expirationDate: string) => {
        return expirationDate ? moment(expirationDate).format('MM-DD-YYYY') : null;
      },
    },
  ];

  const priceSheets = () => {
    return showAllPriceSheets
      ? itemPricesMappedToPriceSheet
      : itemPricesMappedToPriceSheet.filter((priceSheet: CombinedItemPriceSheet) => {
          return (
            (brandPriceSheetIds.includes(priceSheet.id) && priceSheet.used === 1) ||
            (receiver &&
              brandPriceSheetIds.includes(priceSheet.id) &&
              priceSheet.item_prices.length > 0)
          );
        });
  };

  const priceSheetsWithAnalyses = priceSheets().map((priceSheet: CombinedItemPriceSheet) => {
    const analyses = analysesBySegment.filter((a: Analysis & AnalysisType) => {
      return (
        a.reference_id === priceSheet.id ||
        priceSheet.item_prices.find(p => p.id === a.reference_id)
      );
    });
    return { ...priceSheet, analyses };
  });

  const setInitialValues = () => {
    const intialValues: { [key: string]: { [key: string]: number | string | null }[] } = {};

    itemPricesMappedToPriceSheet.forEach((priceSheet: CombinedItemPriceSheet) => {
      const usedTypeIds = usedIds(priceSheet);
      const unUsedParentPriceTypes = unUsedPriceTypes(priceSheet, usedTypeIds) || [];
      const defaultUom = defaultValues.find(
        (def: DefaultValue) => def.resource_table === 'price_uoms'
      );
      const defaultCurrency = defaultValues.find(
        (def: DefaultValue) => def.resource_table === 'price_currencies'
      );

      const combinedExistingAndUsedTypedIds = [
        ...priceSheet.item_prices,
        ...unUsedParentPriceTypes,
      ];

      intialValues[priceSheet.id] = combinedExistingAndUsedTypedIds.map(
        (itemPrice: any, index: number) => {
          const defaultTypeDescription = priceSheet.parent_price_types.find(
            priceType => priceType.type_id === itemPrice.type_id
          );

          return {
            uniqueId: index,
            priceSheetId: priceSheet.id,
            priceSheetCurrencyId: priceSheet.currency_id,
            existingItemPriceid: itemPrice.id || null,
            usedTypeIdInOtherItem: (!itemPrice.price && itemPrice.type_id) || undefined,
            count: itemPrice.count || null,
            typeId: itemPrice.type_id || null,
            price: (itemPrice.price && addMinTwoDecimals(itemPrice.price)) || null,
            priceUomId: itemPrice.price_uom_id || Number(defaultUom?.value) || null,
            currencyId:
              itemPrice.currency_id ||
              priceSheet.currency_id ||
              Number(defaultCurrency?.value) ||
              null,
            priceBreak: itemPrice.price_break || null,
            priceBreakUomId: itemPrice.price_break_uom_id || null,
            effectiveDate: itemPrice.effective_date || null,
            expirationDate: itemPrice.expiration_date || null,
            typeDescription:
              itemPrice.type_description || defaultTypeDescription?.type_description || null,
          };
        }
      );
    });

    return intialValues;
  };

  const handleSubmit = (values: FormikValues, formikActions: CustomFormikActions) => {
    const { setSubmitPending, setSubmitSuccess, setSubmitError } = formikActions;

    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'pricing',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });

    const itemPrices: UpdateItemPrice[] = [];

    const priceSheets = Object.keys(values);

    const updatePriceSheets = showAllPriceSheets
      ? priceSheets
      : priceSheets.filter((sheet: string) => brandPriceSheetIds.includes(Number(sheet)));

    updatePriceSheets.forEach((sheet: string) => {
      values[sheet].forEach((itemPrice: any) => {
        if (itemPrice.typeId && itemPrice.price && itemPrice.priceUomId && itemPrice.currencyId) {
          itemPrices.push({
            price_sheet_id: Number(sheet),
            item_id: itemId,
            id: itemPrice.existingItemPriceid || null,
            type_id: itemPrice.typeId,
            price: itemPrice.price,
            price_uom_id: itemPrice.priceUomId,
            currency_id: itemPrice.currencyId,
            price_break: itemPrice.priceBreak,
            price_break_uom_id: itemPrice.priceBreakUomId,
            effective_date: itemPrice.effectiveDate,
            expiration_date: itemPrice.expirationDate,
          });
        }
      });
    });
    setSubmitPending();
    dispatch(updateItemPrices(itemPrices))
      .then(() => setSubmitSuccess())
      .catch(() => setSubmitError());
  };

  const getValidationSchema = () => {
    const schema: { [key: string]: any } = {};
    itemPricesMappedToPriceSheet.forEach((sheet: { id: string }) => {
      schema[sheet.id] = Yup.array().of(
        Yup.object().shape({
          typeId: Yup.number().nullable().required(t('validation:required')),
          price: Yup.string()
            .nullable()
            .when(
              [
                'usedTypeIdInOtherItem',
                'typeId',
                'priceBreak',
                'priceBreakUomId',
                'effectiveDate',
                'expirationDate',
              ],
              {
                is: (
                  usedTypeIdInOtherItem: number,
                  typeId: number,
                  priceBreak: string,
                  priceBreakUomId: number,
                  effectiveDate: string,
                  expirationDate: string
                ) =>
                  usedTypeIdInOtherItem === typeId &&
                  !priceBreak &&
                  !priceBreakUomId &&
                  !effectiveDate &&
                  !expirationDate,
                then: schema => schema,
                otherwise: schema => schema.required(t('validation:required')),
              }
            ),
          priceUomId: Yup.number()
            .nullable()
            .when(['usedTypeIdInOtherItem', 'typeId', 'price'], {
              is: (usedTypeIdInOtherItem: number, typeId: number, price: string, schema: any) =>
                usedTypeIdInOtherItem === typeId && !price,
              then: schema => schema,
              otherwise: schema => schema.required(t('validation:required')),
            }),
          currencyId: Yup.number()
            .nullable()
            .when(['price'], {
              is: (price: number) => price,
              then: schema => schema.required(t('validation:required')),
              otherwise: schema => schema,
            }),
        })
      );
    });

    return Yup.object().shape(schema);
  };

  return (
    <PageFormik
      showNoError={!canMaintainProduct}
      showAnalysis
      enableReinitialize
      initialValues={setInitialValues()}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      contentNoSpacing
      validationSchema={getValidationSchema()}
    >
      {({ values, setFieldValue }) => {
        return (
          <div>
            {!receiver && editShowPriceSheetsRow()}
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <div style={{ height, width }}>
                    <Table
                      size="small"
                      columns={columns}
                      expandable={{
                        defaultExpandedRowKeys: expandedKeys,
                        expandedRowRender: (record: CombinedItemPriceSheet) => {
                          return (
                            <PriceItemTable
                              itemPrices={values[record.id] || []}
                              itemPriceSheetId={record.id}
                              handleChange={({
                                prevStateRecord,
                                changedStateRecord,
                                newRecord,
                                recordCountId,
                              }: {
                                prevStateRecord?: InitItemPrice;
                                changedStateRecord?: InitItemPrice;
                                newRecord?: InitItemPrice;
                                recordCountId?: number;
                              }) => {
                                const sheetItemPrices: InitItemPrice[] = values[record.id] || [];
                                if (recordCountId) {
                                  const deleteTempRecord = sheetItemPrices.filter(
                                    price =>
                                      prevStateRecord?.priceSheetId === price.priceSheetId &&
                                      price.countId !== recordCountId
                                  );
                                  setFieldValue(record.id.toString(), deleteTempRecord);
                                } else if (newRecord) {
                                  const changedPrices = [...sheetItemPrices, newRecord];
                                  setFieldValue(record.id.toString(), changedPrices);
                                } else if (prevStateRecord?.existingItemPriceid) {
                                  const changedPrices = sheetItemPrices.map(price => {
                                    if (
                                      changedStateRecord?.existingItemPriceid ===
                                      price.existingItemPriceid
                                    )
                                      return changedStateRecord;
                                    return price;
                                  });
                                  setFieldValue(record.id.toString(), changedPrices);
                                } else if (
                                  !prevStateRecord?.existingItemPriceid &&
                                  !prevStateRecord?.countId
                                ) {
                                  const changedPrices = sheetItemPrices.map(price => {
                                    if (prevStateRecord?.typeId === price.typeId)
                                      return changedStateRecord;
                                    return price;
                                  });
                                  setFieldValue(record.id.toString(), changedPrices);
                                } else if (prevStateRecord?.countId) {
                                  const changedPrices = sheetItemPrices.map(price => {
                                    if (prevStateRecord.countId === price.countId)
                                      return changedStateRecord;

                                    return price;
                                  });
                                  setFieldValue(record.id.toString(), changedPrices);
                                }
                              }}
                            />
                          );
                        },
                      }}
                      expandRowByClick
                      dataSource={priceSheetsWithAnalyses || []}
                      pagination={false}
                      rowKey={(record: CombinedItemPriceSheet) => record.id}
                    />
                  </div>
                );
              }}
            </AutoSizer>

            <IntercomFormEventHandler
              segment="pricing"
              partNr={selectedItem?.part_number}
              brandCode={selectedItem?.brand_code}
            />
          </div>
        );
      }}
    </PageFormik>
  );
};

export default PricingPage;
