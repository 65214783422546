import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Popover, Popconfirm, message } from 'antd';
import { List, AutoSizer, ListRowProps } from 'react-virtualized';
import { useTranslation } from 'react-i18next';
import { FormikValues, FormikHelpers } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { ApplicationState } from '../../../reducers';
import { AsyncDispatch } from '../../../../types/global';
import PackageDrawerField from './PackageDrawerField';
import CreatePackageDrawer from './CreatePackageDrawer';
import { updatePackage } from '../../../actions/items/package/update';
import { typingDone } from '../../../utils/Utils';

type PackageDrawerProps = {
  uomId?: number;
  visible: boolean;
  onClose: () => void;
  title: string;
  uomName: string;
  showCreatePkg: boolean;
};

const PackageDrawer: React.FC<PackageDrawerProps> = props => {
  const DEFAULT_ROW_HEIGHT = 40;
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const listRef = React.useRef<List>(null);

  const [showCreatePkg, setShowCreatePkg] = React.useState<boolean>(props.showCreatePkg);

  const {
    allSelectedItemIds,
    selectedItemsList,
    reverseSelected,
    reversedItemIds,
    itemPackages,
    uoms,
  } = useSelector((state: ApplicationState) => ({
    allSelectedItemIds: state.catalogue.catalogue.allSelectedItemIds,
    selectedItemsList: state.catalogue.catalogue.selectedItemsList,
    reverseSelected: state.catalogue.catalogue.reverseSelected,
    reversedItemIds: state.catalogue.catalogue.reversedItemIds,
    itemPackages: state.items.packageSeg.itemPackages,
    uoms: state.resources.data.package.uoms,
  }));

  const [visibleCreatePkgDrawer, setVisibleCreatePkgDrawer] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShowCreatePkg(props.showCreatePkg);
  }, [props.showCreatePkg]);

  const getSelectedItemIds = () =>
    reverseSelected
      ? allSelectedItemIds.filter(id => !reversedItemIds.includes(id))
      : allSelectedItemIds;

  const getSelectedItems = () =>
    selectedItemsList.filter(item => !reversedItemIds.includes(item.id));

  const handleSubmit = (values: FormikValues, formikActions: FormikHelpers<any>) => {
    const { setSubmitting } = formikActions;

    const itemIds = getSelectedItemIds();

    const updatedPackages = itemIds.map(itemId => {
      const itemPkg = itemPackages.find(iPkg => iPkg.item_id === itemId);
      const uomPkg = itemPkg?.packages.find(pkg => pkg.uom_id === props.uomId);
      const packages = itemPkg?.packages || [];
      const changedPackage = values.itemValues.find(
        (pkg: any) => pkg.itemId === itemId && pkg.uom_id
      );

      if (itemPkg) {
        if (uomPkg) {
          const pkgIndex = itemPkg?.packages.findIndex(pkg => pkg.uom_id === props.uomId);
          packages[pkgIndex] = changedPackage;
          return {
            ...itemPkg,
            packages,
          };
        }
        if (!uomPkg && changedPackage)
          return { ...itemPkg, packages: [...itemPkg.packages, changedPackage] };
        return { ...itemPkg, packages: itemPkg.packages };
      }
      return { item_id: itemId, packages: changedPackage ? [changedPackage] : [] };
    });
    setSubmitting(true);
    dispatch(updatePackage(updatedPackages))
      .then(() => {
        setSubmitting(false);
        props.onClose();
      })
      .catch(e => {
        setSubmitting(false);
        message.error(e.message);
      });
  };

  const validationSchema = Yup.object().shape(
    {
      uom_id: Yup.number(),
      itemValues: Yup.array().of(
        Yup.object().shape({
          quantity_of_eaches: Yup.number()
            .nullable()
            .when('uom_id', {
              is: true,
              then: schema => schema.nullable().required(t('validation:required')),
              otherwise: schema => schema.notRequired(),
            }),
        })
      ),
    },
    [['quantity_of_eaches', 'uom_id']]
  );

  const getInitialValues = () => {
    const itemIds = getSelectedItemIds();

    return itemIds.map(itemId => {
      const item = itemPackages.find(itemPackage => itemPackage.item_id === itemId);
      const pkg = item?.packages.find(pkg => pkg.uom_id === props.uomId);

      return pkg
        ? { ...pkg, itemId }
        : {
            itemId,
            uom_id: null,
          };
    });
  };

  return (
    <DrawerFormik
      enableReinitialize
      title={props.title}
      visible={props.visible}
      onClose={() => props.onClose()}
      initialValues={{ itemValues: getInitialValues() }}
      width="60%"
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      additionalFooterData={
        <Button
          data-testid="create-pkg"
          onClick={() => setVisibleCreatePkgDrawer(true)}
          disabled={!showCreatePkg}
        >
          {t('packageSeg:createPkg')}
        </Button>
      }
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, setFieldValue, setFieldTouched, errors, touched }) => {
        typingDone(() => listRef.current?.recomputeRowHeights());

        const selectedItems = getSelectedItems();

        const replaceAllValues = (
          inputValue: any,
          title: string,
          selectValue?: number,
          height?: string,
          length?: string
        ) => {
          let itemValues = values.itemValues;

          if (title.includes(t('packageSeg:package.quantityEach'))) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              quantity_of_eaches: Number(inputValue) || null,
            }));
          }
          if (title.includes(t('packageSeg:package.innerQuantity'))) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              inner_quantity: inputValue,
              inner_quantity_uom_id: selectValue || null,
            }));
          }
          if (title.includes(`${t('packageSeg:package.shippingDimensions')}`)) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              width: inputValue,
              dimensions_uom_id: selectValue || null,
              height,
              length,
            }));
          }
          if (title.includes(t('packageSeg:package.weight'))) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              weight: inputValue,
              weights_uom_id: selectValue || null,
            }));
          }
          if (title.includes(`${t('packageSeg:package.weightVariance')}`)) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              weight_variance: inputValue,
            }));
          }
          if (title.includes(`${t('packageSeg:package.merchandisingDimensions')}`)) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              merchandising_width: inputValue,
              dimensions_uom_id: selectValue || null,
              merchandising_height: height,
              merchandising_length: length,
            }));
          }
          if (title.includes(t('packageSeg:package.orderable'))) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              orderable_option_id: selectValue || null,
            }));
          }
          if (title.includes(t('packageSeg:package.stackingFactor'))) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              stacking_factor: inputValue,
            }));
          }
          if (title.includes(t('packageSeg:package.levelGtin'))) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              level_gtin: inputValue,
            }));
          }
          if (title.includes(t('packageSeg:package.electronicProductCode'))) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              electronic_product_code: inputValue,
            }));
          }
          if (title.includes(t('packageSeg:package.barcodeChar'))) {
            itemValues = values.itemValues.map((pkg: any) => ({
              ...pkg,
              bar_code_characters: inputValue,
            }));
          }
          setFieldValue('itemValues', itemValues);
        };

        const rowRenderer = ({ key, index, style }: ListRowProps) => {
          const item = selectedItems[index];

          const pkg = values.itemValues[index];

          const valueErrors = errors.itemValues;
          const touchedValues = touched.itemValues;
          const showValidationInfo =
            Array.isArray(valueErrors) &&
            !!valueErrors[index] &&
            Array.isArray(touchedValues) &&
            !!touchedValues[index];

          return (
            <div style={style} key={key} className="package__drawer-list-row">
              <div className="package__drawer-list-row_data">{item.part_number}</div>

              <div className="package__drawer-list-row_data">
                {item.short_name ? (
                  <Popover content={item.short_name}>
                    <span>{item.short_name}</span>
                  </Popover>
                ) : (
                  <span className="italic text-gray-600">{t('packageSeg:noPartType')}</span>
                )}
              </div>

              <Form.Item
                hasFeedback
                validateStatus={showValidationInfo ? 'error' : ''}
                help={(showValidationInfo && t('validation:required')) || undefined}
              >
                <PackageDrawerField
                  title={props.title}
                  uoms={uoms}
                  disabled={!pkg?.uom_id}
                  uomName={props.uomName}
                  values={pkg}
                  handleOnChange={(value, name) => {
                    setFieldValue(`itemValues[${index}].${name}`, value || null);
                  }}
                  handleOnBlur={() => setFieldTouched(`itemValues[${index}].quantity_of_eaches`)}
                />
              </Form.Item>
            </div>
          );
        };

        return (
          <React.Fragment>
            <Form
              layout="vertical"
              className="h-full flex flex-col package__drawer overflow-hidden"
            >
              <div className="package__drawer-list-row font-medium text-black bg-gray-200">
                <div className="package__drawer-list-row_data">{t('packageSeg:partNumber')}</div>

                <div className="package__drawer-list-row_data">
                  {t('packageSeg:productDescription')}
                </div>

                <div className="flex">
                  <div className="flex-1">{t('packageSeg:value')}</div>
                  <div className="text-right">
                    <Popconfirm
                      placement="left"
                      title={t('packageSeg:warnClearText')}
                      onConfirm={() => replaceAllValues('', props.title)}
                      okText={t('common:yes')}
                      cancelText={t('common:cancel')}
                      onCancel={e => e?.stopPropagation()}
                      disabled={showCreatePkg}
                    >
                      <Button size="small" disabled={showCreatePkg}>
                        {t('packageSeg:clearAll')}
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
              <div className="package__drawer-list-row">
                <div />
                <div className="package__drawer-list-row_text font-medium text-black">
                  {t('packageSeg:overwriteAllValues')}
                </div>
                <div className={classNames('package-drawer__overwrite-wrapper')}>
                  <PackageDrawerField
                    title={props.title}
                    uoms={uoms}
                    displayOk
                    uomId={props.uomId!}
                    disabled={showCreatePkg}
                    handleReplace={(inputValue, selectValue, height, length) =>
                      replaceAllValues(inputValue, props.title, selectValue, height, length)
                    }
                    uomName={props.uomName}
                    overWriteInfo
                  />
                </div>
              </div>

              <div className="flex-1">
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      ref={listRef}
                      width={width}
                      height={height}
                      rowCount={selectedItems.length}
                      rowRenderer={rowRenderer}
                      rowHeight={({ index }) => {
                        const showValidationInfo =
                          Array.isArray(errors.itemValues) &&
                          !!errors.itemValues[index] &&
                          Array.isArray(touched.itemValues) &&
                          !!touched.itemValues[index];
                        return showValidationInfo ? DEFAULT_ROW_HEIGHT + 20 : DEFAULT_ROW_HEIGHT;
                      }}
                    />
                  )}
                </AutoSizer>
              </div>
            </Form>
            <CreatePackageDrawer
              visible={visibleCreatePkgDrawer}
              uomId={props.uomId!}
              title={`${t('packageSeg:package.quantityEach')} „${props.uomName}”`}
              uoms={uoms}
              onClose={(sucessSubmit?: boolean) => {
                setVisibleCreatePkgDrawer(false);
                if (sucessSubmit) setShowCreatePkg(false);
              }}
            />
          </React.Fragment>
        );
      }}
    </DrawerFormik>
  );
};

export default PackageDrawer;
