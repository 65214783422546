import axios from 'axios';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import { Application } from '../../../../types/application';
import { getMenuConfigKeys } from '../../../utils/ApplicationUtils';

const CancelToken = axios.CancelToken;

let fetchApplicationsCancelToken: any;

export function fetchItemApplications(
  itemId: number,
  keywords?: string,
  page = 1,
  itemChanged?: boolean
) {
  if (fetchApplicationsCancelToken) fetchApplicationsCancelToken();

  const urlParams = utils.createURL([
    { name: 'item_ids', values: itemId },
    { name: 'keywords', values: keywords },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_ITEM_APPLICATIONS',
    meta: { page, itemChanged, keywords },
    payload: axios.get(`${getApiRoot()}/v2/items/applications${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchApplicationsCancelToken = c;
      }),
    }),
  };
}

export function fetchApplication(itemId: number, applicationId: number) {
  const urlParams = utils.createURL([
    { name: 'item_ids', values: itemId },
    { name: 'item_application_id', values: applicationId },
  ]);

  return {
    type: 'FETCH_APPLICATION',
    payload: axios.get(`${getApiRoot()}/v2/items/applications${urlParams}`),
  };
}

let fetchGridRanksCancelToken: any;

export function fetchGridRanks(application: any, versionIds?: number[], rankEquipment?: boolean) {
  if (fetchGridRanksCancelToken) fetchGridRanksCancelToken();

  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'category_ids', values: application.category_id || application.item_category_id },
    { name: 'rank_equipment', values: utils.booleanToInt(rankEquipment) },
  ]);

  const keys = Object.keys(application).filter(
    key => key.includes('_ids') || ['years'].includes(key)
  );

  const subConfigs: { [key: string]: any } = {};
  keys.forEach(key => (subConfigs[key] = application[key]));

  return {
    type: 'FETCH_GRID_RANKED_APPLICATIONS',
    payload: axios.post(
      `${getApiRoot()}/valid_configs${urlParams}`,
      { sub_configs: subConfigs },
      {
        cancelToken: new CancelToken(c => {
          fetchGridRanksCancelToken = c;
        }),
      }
    ),
    meta: { empty: keys.length === 0 },
  };
}

let fetchRanksCancelToken: any;

export function fetchRankedApplications(
  application: Omit<Application, 'item_application_id'>,
  versionIds?: number[],
  keywords?: string,
  rankEquipment?: boolean
) {
  if (fetchRanksCancelToken) fetchRanksCancelToken();

  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'category_ids', values: application.category_id || application.item_category_id },
    { name: 'keywords', values: utils.stringToArray(keywords) },
    { name: 'rank_equipment', values: utils.booleanToInt(rankEquipment) },
  ]);

  const keys = Object.keys(application).filter(
    key => key.includes('_ids') || ['years'].includes(key)
  );

  const subConfigs: { [key: string]: any } = {};
  keys.forEach(key => (subConfigs[key] = application[key]));

  return {
    type: 'FETCH_RANKED_APPLICATIONS',
    payload: axios.post(
      `${getApiRoot()}/v2/applications/ranks${urlParams}`,
      { sub_configs: subConfigs },
      {
        cancelToken: new CancelToken(c => {
          fetchRanksCancelToken = c;
        }),
      }
    ),
  };
}

let fetchVisibleRanksCancelToken: any;

export function fetchVisibleRanks(
  application: Omit<Application, 'item_application_id'>,
  selectedMenuKey: string,
  versionIds?: number[],
  rankEquipment?: boolean,
  selectedConfigName?: string
) {
  if (fetchVisibleRanksCancelToken) fetchVisibleRanksCancelToken();
  const visibleKeys = getMenuConfigKeys(selectedMenuKey);
  if (selectedConfigName && !visibleKeys?.includes(selectedConfigName))
    visibleKeys?.push(selectedConfigName);

  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'category_ids', values: application.category_id || application.item_category_id },
    { name: 'rank_equipment', values: utils.booleanToInt(rankEquipment) },
    { name: 'sub_config_types', values: visibleKeys },
  ]);

  const keys = Object.keys(application).filter(
    key => key.includes('_ids') || ['years'].includes(key)
  );

  const subConfigs: { [key: string]: any } = {};
  keys.forEach(key => (subConfigs[key] = application[key]));

  return {
    type: 'FETCH_VISIBLE_APPLICATION_RANKS',
    payload: axios.post(
      `${getApiRoot()}/v2/applications/ranks${urlParams}`,
      { sub_configs: subConfigs },
      {
        cancelToken: new CancelToken(c => {
          fetchVisibleRanksCancelToken = c;
        }),
      }
    ),
  };
}

let fetchValidConfigsCancelToken: any;

export function fetchValidConfigs(
  application: Omit<Application, 'item_application_id'>,
  versionIds?: number[],
  rankEquipment?: boolean
) {
  if (fetchValidConfigsCancelToken) fetchValidConfigsCancelToken();

  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'category_ids', values: application.category_id || application.item_category_id },
    { name: 'rank_equipment', values: utils.booleanToInt(rankEquipment) },
  ]);

  const subConfigs: { [key: string]: any } = rankEquipment
    ? {
        mfr_ids: application.mfr_ids,
        equipment_model_ids: application.equipment_model_ids,
        years: application.years,
      }
    : {
        make_ids: application.make_ids,
        model_ids: application.model_ids,
        years: application.years,
      };

  return {
    type: 'FETCH_RANKED_BASE_APPLICATIONS',
    payload: axios.post(
      `${getApiRoot()}/v2/applications/ranks${urlParams}`,
      { sub_configs: subConfigs },
      {
        cancelToken: new CancelToken(c => {
          fetchValidConfigsCancelToken = c;
        }),
      }
    ),
  };
}

export function fetchApplicationMakesModelsYears(applicationId: number) {
  return {
    type: 'FETCH_APPLICATION_MAKES_MODELS_YEARS',
    payload: axios.get(`${getApiRoot()}/vehicles/bases?application_id=${applicationId}`),
  };
}

export function fetchApplicationMakes(brandId: number, versionIds: number[], keywords?: string) {
  keywords = keywords || '';
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_APPLICATION_MAKES',
    payload: axios.get(`${getApiRoot()}/vehicles/bases/makes${urlParams}`),
  };
}

export function fetchApplicationModels(
  brandId: number,
  versionIds: number[],
  makeId?: number | null,
  keywords?: string
) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_APPLICATION_MODELS',
    payload: axios.get(`${getApiRoot()}/vehicles/bases/models${urlParams}`),
  };
}

export function fetchApplicationYears(versionIds: number[], makeId?: number, modelId?: number) {
  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
  ]);

  return {
    type: 'FETCH_APPLICATION_YEARS',
    payload: axios.get(`${getApiRoot()}/vehicles/bases/years${urlParams}`),
  };
}

export function fetchApplicationSubmodels(
  brandId: number,
  versionIds: number[],
  makeId?: number,
  modelId?: number,
  keywords?: string
) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_APPLICATION_SUBMODELS',
    payload: axios.get(`${getApiRoot()}/vehicles/sub_models${urlParams}`),
  };
}

export function fetchApplicationRegions(
  brandId: number,
  versionIds: number[],
  makeId: number,
  modelId: number
) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
  ]);

  return {
    type: 'FETCH_APPLICATION_REGIONS',
    payload: axios.get(`${getApiRoot()}/vehicles/regions${urlParams}`),
  };
}

export function fetchVcdbMakes(brandId: number, versionIds: number[], keywords?: string) {
  keywords = keywords || '';
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_VCDB_MAKES',
    payload: axios.get(`${getApiRoot()}/vehicles/bases/makes${urlParams}`),
  };
}

export function fetchVcdbModels(
  brandId: number,
  versionIds: number[],
  makeId?: number | null,
  keywords?: string
) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_VCDB_MODELS',
    payload: axios.get(`${getApiRoot()}/vehicles/bases/models${urlParams}`),
  };
}

export function fetchVcdbYears(makeId: number, modelId: number) {
  const urlParams = utils.createURL([
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
  ]);

  return {
    type: 'FETCH_VCDB_YEARS',
    payload: axios.get(`${getApiRoot()}/vehicles/bases/years${urlParams}`),
  };
}

export function fetchVcdbSubmodels(
  brandId: number,
  versionIds: number[],
  makeId?: number,
  modelId?: number,
  keywords?: string
) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
    { name: 'keywords', values: utils.stringToArray(keywords) },
  ]);

  return {
    type: 'FETCH_VCDB_SUBMODELS',
    payload: axios.get(`${getApiRoot()}/vehicles/sub_models${urlParams}`),
  };
}

export function fetchVcdbRegions(
  brandId: number,
  versionIds: number[],
  makeId: number,
  modelId: number
) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'make_id', values: makeId },
    { name: 'model_id', values: modelId },
  ]);

  return {
    type: 'FETCH_VCDB_REGIONS',
    payload: axios.get(`${getApiRoot()}/vehicles/regions${urlParams}`),
  };
}

export function fetchEquipmentYears(
  brandId: number,
  versionIds: number[],
  mfrId?: number,
  equipmentModelId?: number,
  vehicleTypeId?: number
) {
  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'version_ids', values: versionIds },
    { name: 'mfr_id', values: mfrId },
    { name: 'equipment_model_id', values: equipmentModelId },
    { name: 'vehicle_type_id', values: vehicleTypeId },
  ]);

  return {
    type: 'FETCH_EQUIPMENT_YEARS',
    payload: axios.get(`${getApiRoot()}/vehicles/production_years${urlParams}`),
  };
}

export function fetchApplicationQualifiers(applicationId: number) {
  const qualifiers = axios.get(`${getApiRoot()}/items/applications/${applicationId}/qualifiers`);

  return {
    type: 'FETCH_APPLICATION_QUALIFIERS',
    payload: qualifiers,
  };
}

export function fetchDefaultQualifiers(itemId: number) {
  const qualifiers = axios.get(`${getApiRoot()}/items/${itemId}/qualifiers`);

  return {
    type: 'FETCH_DEFAULT_QUALIFIERS',
    payload: qualifiers,
  };
}

export function fetchDefaultNotes(itemId: number) {
  return {
    type: 'FETCH_DEFAULT_NOTES',
    payload: axios.get(`${getApiRoot()}/items/${itemId}/notes`),
  };
}

export function fetchRankedPositions(categoryId: number) {
  return {
    type: 'FETCH_RANKED_POSITIONS',
    payload: axios.get(`${getApiRoot()}/categories/${categoryId}/positions`),
  };
}

export function fetchBundlePositions(categoryId: number) {
  return {
    type: 'FETCH_BUNDLE_POSITIONS',
    payload: axios.get(`${getApiRoot()}/categories/${categoryId}/positions`),
    meta: { categoryId },
  };
}

export function fetchQualifiers(
  itemId: number,
  keywords?: string | undefined,
  typeId?: number,
  includesValues?: boolean,
  page = 1
) {
  const urlParams = utils.createURL([
    { name: 'item_id', values: itemId },
    { name: 'keywords', values: utils.stringToArray(keywords) },
    { name: 'type_id', values: typeId },
    { name: 'includes_values', values: utils.booleanToInt(includesValues) },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
  ]);

  const qualifiers = axios.get(`${getApiRoot()}/qualifiers${urlParams}`);

  return {
    type: 'FETCH_QUALIFIERS',
    meta: { page },
    payload: qualifiers,
  };
}

export function fetchPartTypeCategories(brandId: number, keywords?: string, page = 1) {
  const params = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'keywords[0]', values: keywords },
    { name: 'limit', values: utils.getPageLimit() },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_APPLICATION_PART_TYPE_CATEGORIES',
    meta: { page },
    payload: axios.get(`${getApiRoot()}/categories${params}`),
  };
}

export function fetchApplicationDigitalAssets(applicationId: number) {
  return {
    type: 'FETCH_APPLICATION_DIGITAL_ASSETS',
    payload: axios.get(`${getApiRoot()}/items/applications/${applicationId}/digital_assets`),
  };
}

export function fetchItemsByKeyword(
  brandId: number | null,
  selectedIds: number[],
  keyword?: string,
  page = 1
) {
  const params = utils.createURL([
    { name: 'parent_owner_brand_id', values: brandId },
    { name: 'order_condition', values: 'updated_at' },
    { name: 'descending', values: 1 },
    { name: 'page', values: page },
    { name: 'keywords', values: keyword },
    { name: 'limit', values: 50 }, // Fixed limit of 50 results for performance reasons
  ]);

  return {
    type: 'FETCH_APPLICATION_PARENT_ITEMS',
    payload: axios.post(`${getApiRoot()}/items/search${params}`),
    meta: { keyword, page, selectedIds },
  };
}

export function fetchApplicationParentItems({
  brandId,
  itemIds,
  page = 1,
}: {
  brandId: number;
  itemIds: number[];
  page?: number;
}) {
  if (itemIds.length === 0)
    throw new Error('fetchApplicationParentItems not allowed without empty list of itemIds');
  const itemIdsString = itemIds.join(',');
  const params = utils.createURL([
    { name: 'parent_owner_brand_id', values: brandId },
    { name: 'page', values: page },
    { name: 'limit', values: itemIds.length },
  ]);

  return {
    type: 'FETCH_APPLICATION_PARENT_ITEMS',
    payload: axios.post(`${getApiRoot()}/items/search${params}`, { item_ids: itemIdsString }),
    meta: { selectedIds: itemIds },
  };
}

export function fetchApplicationChildItems({
  brandId,
  itemIds,
  page = 1,
}: {
  brandId: number;
  itemIds: number[];
  page?: number;
}) {
  if (itemIds.length === 0)
    throw new Error('fetchApplicationChildItems not allowed without empty list of itemIds');
  const itemIdsString = itemIds.join(',');
  const params = utils.createURL([
    { name: 'parent_owner_brand_id', values: brandId },
    { name: 'page', values: page },
    { name: 'limit', values: itemIds.length },
  ]);

  return {
    type: 'FETCH_APPLICATION_CHILD_ITEMS',
    payload: axios.post(`${getApiRoot()}/items/search${params}`, { item_ids: itemIdsString }),
  };
}

export function fetchApplicationLinks(itemId: number) {
  const params = utils.createURL([{ name: 'item_id', values: itemId }]);

  return {
    type: 'FETCH_APPLICATION_LINKS',
    payload: axios.get(`${getApiRoot()}/items/bases${params}`),
  };
}

export function fetchLinkingItems(itemId: number) {
  const params = utils.createURL([{ name: 'item_id', values: itemId }]);

  return {
    type: 'FETCH_APPLICATION_LINKED_ITEMS',
    payload: axios.get(`${getApiRoot()}/items/bases/linked_items${params}`),
  };
}

let fetchBundleCancelToken: any;

export function fetchBundleItems(itemId: number) {
  if (fetchBundleCancelToken) fetchBundleCancelToken();
  const params = utils.createURL([{ name: 'base_item_id', values: itemId }]);

  return {
    type: 'FETCH_APPLICATION_BUNDLE_ITEMS',
    payload: axios.get(`${getApiRoot()}/items/bases${params}`, {
      cancelToken: new CancelToken(c => {
        fetchBundleCancelToken = c;
      }),
    }),
  };
}

export function fetchApplicationListFilter(filterId: number) {
  return {
    type: 'FETCH_APPLICATION_LIST_FILTER',
    payload: axios.get(`${getApiRoot()}/filters/${filterId}`),
  };
}

let fetchCancelToken: any;

export function fetchItemApplicationListing(
  versionIds: number[],
  filterId: number,
  itemId: number,
  keywords: string,
  typeId: number,
  typeOptionId?: number,
  page = 1
) {
  if (fetchCancelToken) fetchCancelToken();

  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'filter_id', values: filterId },
    { name: 'item_ids', values: [itemId] },
    { name: 'type_id', values: typeId },
    { name: 'type_option_id', values: typeOptionId },
    { name: 'keywords', values: keywords },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_ITEM_APPLICATION_LISTINGS',
    payload: axios.get(`${getApiRoot()}/items/applications/listings${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchCancelToken = c;
      }),
    }),
    meta: { page },
  };
}

let fetchGridCancelToken: any;

export function fetchItemApplicationGrids(
  versionIds: number[],
  filterId: number,
  itemId: number,
  keywords: string,
  page = 1
) {
  if (fetchGridCancelToken) fetchGridCancelToken();

  const urlParams = utils.createURL([
    { name: 'version_ids', values: versionIds },
    { name: 'filter_id', values: filterId },
    { name: 'item_id', values: itemId },
    { name: 'type_id', values: 1 },
    { name: 'type_option_id', values: 1 },
    { name: 'keywords', values: keywords },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_ITEM_APPLICATION_GRIDS',
    payload: axios.get(`${getApiRoot()}/items/applications/grids${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchGridCancelToken = c;
      }),
    }),
    meta: { page },
  };
}

let fetchGroupDataCancelToken: any;

export function fetchApplicationGroupData(
  applicationId: number,
  itemId: number,
  page = 1,
  limit = 100
) {
  if (fetchGroupDataCancelToken) fetchGroupDataCancelToken();

  const urlParams = utils.createURL([
    { name: 'application_id', values: applicationId },
    { name: 'item_id', values: itemId },
    { name: 'type_id', values: 1 },
    { name: 'type_option_id', values: 1 },
    { name: 'limit', values: limit },
    { name: 'page', values: page },
  ]);

  return {
    type: 'FETCH_APPLICATION_GROUP_DATA',
    payload: axios.get(`${getApiRoot()}/items/applications/expands${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchGroupDataCancelToken = c;
      }),
    }),
    meta: { page },
  };
}

let fetchGridPreviewCancelToken: any;

export function fetchGridPreview(
  application: any,
  itemId: number,
  versionIds: number[],
  equipment: boolean,
  sortBy?: string,
  sortOrder?: string,
  page = 1
) {
  if (fetchGridPreviewCancelToken) fetchGridPreviewCancelToken();

  const urlParams = utils.createURL([
    { name: 'item_id', values: itemId },
    { name: 'version_ids', values: versionIds },
    { name: 'equipment', values: utils.booleanToInt(equipment) },
    { name: 'sort_by', values: sortBy },
    {
      name: 'sort_asc',
      values: sortOrder !== undefined ? utils.booleanToInt(sortOrder === 'asc') : undefined,
    },
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
  ]);

  const keys = Object.keys(application).filter(
    key => key.includes('_ids') || ['years'].includes(key)
  );

  const subConfigs: { [key: string]: any } = {};
  keys.forEach(key => (subConfigs[key] = application[key]));

  return {
    type: 'FETCH_GRID_PREVIEW_APPLICATIONS',
    payload: axios.post(
      `${getApiRoot()}/items/applications/grids/previews${urlParams}`,
      { sub_configs: subConfigs },
      {
        cancelToken: new CancelToken(c => {
          fetchGridPreviewCancelToken = c;
        }),
      }
    ),
  };
}

export function fetchVcdbVersions() {
  return {
    type: 'FETCH_VCDB_VERSIONS',
    payload: axios.get(`${getApiRoot()}/vehicles/versions`),
  };
}

export function fetchApplicationResources(initial?: boolean) {
  return {
    type: 'FETCH_APPLICATION_RESOURCES',
    payload: axios.get(`${getApiRoot()}/accounts/resources`),
    meta: { initial },
  };
}
