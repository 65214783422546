import { Button, Col, Form, Row, Switch, Modal } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UnlockOutlined } from '@ant-design/icons';

import { SelectValue } from 'antd/lib/select';
import { ApplicationState } from '../../../reducers';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import { FilterType } from '../../../../types/filter';
import {
  ExtendedResources,
  StandardResourceCodeDescription,
  StandardResourceConfig,
} from '../../../../types/resources';
import {
  Plan,
  FTP,
  Schedule,
  FileNameTemplate,
  ChannelFileExportOption,
  ChannelExportOptions,
  FileOptions,
} from '../../../../types/channel';
import { getAllCustomFilters } from '../../../selectors/catalogue/filterSelector';
import { FormSegments } from './ChannelDefaultSettings';
import TimeInput from './TimeInput';
import {
  deliveryValidationSchema,
  planValidationSchema,
  receiverPlanValidationSchema,
  scheduleValidationSchema,
} from '../../../utils/ChannelValidationSchemas';
import DeliveryFormPart from './DeliveryFormPart';
import FormInput from '../../global/Forms/FormInput';
import FormRadio from '../../global/Forms/FormRadio';
import FormSelect from '../../global/Forms/FormSelect';
import SwitchDivider from '../../global/SwitchDivider';
import FormErrorFocus from '../../global/Forms/FormErrorFocus';
import ChannelFormDirtyHandler from './ChannelFormDirtyHandler';
import HelpCenter from '../../../containers/HelpCenter';
import days from '../../../constants/DayConstants.json';
import { intToBoolean } from '../../../utils/Utils';
import { namingConventionPreview } from '../../../utils/ChannelUtils';
import ExportFormatOptions from '../import_export/ExportFormatOptions';
import { FileExportOption } from '../../../../types/import_export';
import { hasPermission } from '../../../utils/Permissions';
import { BrandCustomField } from '../../../../types/custom_fields';

type EditChannelPlanProps = {
  create: boolean;
  isReceiverUser: boolean;
  showWhitelabel: boolean;
  canManageAdvancedExportOptions: boolean;
  plan: Plan;
  defaultFtp: any;
  defaultFile?: ChannelFileExportOption[];
  defaultTemplateId?: number | null;
  defaultSchedule: any;
  filters: FilterType[];
  deliveryFrequencies: ExtendedResources[];
  deliveryTypes: ExtendedResources[];
  ftpDirectoryTypes: ExtendedResources[];
  protocolTypes: ExtendedResources[];
  fileTypes: ExtendedResources[];
  fileExportOptions: FileExportOption[];
  fileNameConfigOptions: StandardResourceCodeDescription[];
  defaultFileNameTemplate: StandardResourceConfig;
  fileNameTemplates: FileNameTemplate[];
  brandCustomFields: BrandCustomField[];
  updatePlan: (arg1: any, arg2: any, fileExportOptions: ChannelFileExportOption[]) => Promise<any>;
  selectPlan: (planId: string) => void;
  createPlan: (arg1: any, arg2: any, fileExportOptions: ChannelFileExportOption[]) => Promise<any>;
  deletePlan: (id: string) => void;
  openNamingConventionDrawer: () => void;
  deleteExportFtpSettings: (planId: number) => void;
  deleteExportFileSettings: (planId: number) => void;
  deleteSchedule: (planId: number) => void;
  onClose: (force?: boolean) => void;
} & WithTranslation;

type EditChannelPlanState = {
  activeDefaultSettings: FormSegments[];
  activeWhitelabel: boolean;
  defaultPlanDisabled: boolean;
  currentFileTypeId: number;
};

export class EditChannelPlan extends React.Component<EditChannelPlanProps, EditChannelPlanState> {
  constructor(props: EditChannelPlanProps) {
    super(props);
    this.state = {
      activeDefaultSettings: this.getPlanDefaultSegments(),
      activeWhitelabel: !!(
        props.plan.whitelabel_brand_code || props.plan.whitelabel_custom_field_id
      ),
      defaultPlanDisabled: intToBoolean(props.plan.default) || false,
      currentFileTypeId: this.props.plan.file_type_id || 1,
    };
  }

  getPlanDefaultSegments = () => {
    const { plan, defaultFtp, defaultFile, defaultSchedule } = this.props;
    return [
      !!(!plan.ftp && defaultFtp) && FormSegments.DELIVERY,
      !!(!plan.file && defaultFile) && FormSegments.FILE,
      !!(!plan.schedule && defaultSchedule) && FormSegments.SCHEDULE,
    ]
      .filter(segment => segment !== false)
      .sort() as FormSegments[];
  };

  getFilters = () => {
    const { t, plan, filters } = this.props;
    const filterId = plan.filter_ids?.length ? plan.filter_ids[0] : undefined;
    if (filterId && !filters.find(filter => filter.id === filterId)) {
      return [
        {
          id: filterId,
          name: `${plan.filter_names && plan.filter_names[0]} (${t('channel:otherUsersFilter')})`,
        },
        ...filters,
      ];
    }
    return filters;
  };

  getNamingConventionPreviewText = (templateId: number) => {
    const { fileNameTemplates, fileNameConfigOptions, defaultFileNameTemplate } = this.props;
    const template =
      fileNameTemplates.find(template => template.id === templateId) || defaultFileNameTemplate;

    if (template) return namingConventionPreview(template.config, fileNameConfigOptions);
    return '';
  };

  getAllowedOptions = (fileType?: FileExportOption) => {
    if (fileType && fileType.export_options)
      return this.props.canManageAdvancedExportOptions
        ? fileType?.export_options
        : fileType?.export_options.filter(option => option.advanced === 0);
    return [];
  };

  getExportFileType = (fileTypeId: number | null) => {
    return this.props.fileExportOptions.find(fileTypeOption => fileTypeOption.id === fileTypeId);
  };

  getFileName = (id: number) =>
    this.props.fileExportOptions.find(fileType => fileType.id === id)?.name || '';

  getExportOptionsSchema = (value: any) => {
    const { t } = this.props;
    if (typeof value === 'number') return Yup.number().required(t('validation:required'));
    if (typeof value === 'object') {
      const length = value ? value.length - 1 : 0;
      if (length > 0) return Yup.array();
      return Yup.array().nullable().required(t('validation:required'));
    }
    return Yup.string().required(t('validation:required'));
  };

  schema = () => {
    const fileExportOption: FileExportOption | undefined = this.getExportFileType(
      this.state.currentFileTypeId!
    );
    const filteredExportOptions = this.getAllowedOptions(fileExportOption);

    const mandatoryExportOptions = filteredExportOptions?.filter(option => option.mandatory === 1);

    const exportOptionsSchema: any = {};

    mandatoryExportOptions?.forEach(f => {
      exportOptionsSchema[f.code] = Yup.lazy(value => this.getExportOptionsSchema(value));
    });

    return exportOptionsSchema;
  };

  getValidationSchema = () => {
    const { t } = this.props;
    const { activeDefaultSettings } = this.state;
    const { activeWhitelabel } = this.state;

    const fileTypeName = this.props.fileExportOptions.find(
      fileType => fileType.id === this.state.currentFileTypeId
    )?.name;

    return Yup.object().shape({
      whitelabelBrandCode: activeWhitelabel
        ? Yup.string().required(t('validation:required'))
        : Yup.string(),
      ...(this.props.isReceiverUser ? receiverPlanValidationSchema : planValidationSchema),
      delivery: Yup.object().shape(deliveryValidationSchema),
      ...scheduleValidationSchema,
      exportOptions: !activeDefaultSettings.includes(FormSegments.FILE)
        ? Yup.object().shape({
            [fileTypeName!]: Yup.object().shape({
              ...this.schema(),
            }),
          })
        : Yup.mixed().notRequired(),
      deliveryFrequencyId: Yup.number().when(['delivery.deliveryTypeId'], {
        is: (deliveryTypeId: number) => deliveryTypeId === 3,
        then: (schema: any) =>
          schema.test(
            'delivery-method-selected',
            t('validation:noDeliveryMethod'),
            (deliveryFrequencyId: number) => {
              if (deliveryFrequencyId !== 4) return false;
              return true;
            }
          ),
        otherwise: schema => schema,
      }),
    });
  };

  handlePlanDelete = (planId: number) => {
    const { t, deletePlan } = this.props;

    Modal.confirm({
      title: t('channel:deactivatePlanMessage'),
      okText: t('common:yes'),
      cancelText: t('common:cancel'),
      onOk() {
        deletePlan(planId.toString());
      },
    });
  };

  handlePlanChange = (values: any) => {
    const { plan, defaultTemplateId, defaultFile } = this.props;
    const { activeDefaultSettings } = this.state;
    const defaults = {
      defaultDelivery: activeDefaultSettings.includes(FormSegments.DELIVERY),
      defaultFile:
        activeDefaultSettings.includes(FormSegments.FILE) &&
        values.fileNameTemplateId === defaultTemplateId,
      defaultSchedule: activeDefaultSettings.includes(FormSegments.SCHEDULE),
    };

    values = {
      ...values,
      whitelabelCustomFieldId:
        values.whitelabelCustomFieldId === 0 ? null : values.whitelabelCustomFieldId,
    };

    const initialDefaults = this.getPlanDefaultSegments();
    const addedDef = activeDefaultSettings.filter(segment => !initialDefaults.includes(segment));

    if (addedDef.length > 0) {
      if (addedDef.includes(FormSegments.DELIVERY)) this.props.deleteExportFtpSettings(plan.id);
      if (addedDef.includes(FormSegments.FILE)) this.props.deleteExportFileSettings(plan.id);
      if (addedDef.includes(FormSegments.SCHEDULE)) this.props.deleteSchedule(plan.id);
    }
    let fileExportOptions: ChannelFileExportOption[] = [];

    if (!values.activeDefaultSettings.includes(FormSegments.FILE)) {
      const fileExportOption: FileExportOption | undefined = this.getExportFileType(
        values.fileTypeId
      );

      const filteredExportOptions = values.advancedOptionsEnabled
        ? this.getAllowedOptions(fileExportOption)
        : this.getAllowedOptions(fileExportOption).filter(option => option.advanced === 0);

      const planFileExportOptions: ChannelExportOptions[] = filteredExportOptions?.map(option => {
        return {
          option_id: option.id,
          parameters: values.exportOptions[values.fileTypeName][option.code],
        };
      });
      fileExportOptions.push({
        file_type_id: values.fileTypeId,
        export_options: planFileExportOptions,
      });
    } else if (defaultFile) {
      fileExportOptions = defaultFile;
    }

    if (this.props.create) return this.props.createPlan(values, defaults, fileExportOptions);
    return this.props.updatePlan(values, defaults, fileExportOptions);
  };

  addDefaultSetting = (fs: FormSegments, setFieldValue: FormikHelpers<any>['setFieldValue']) => {
    const { activeDefaultSettings } = this.state;
    const selectedDefaults = [...activeDefaultSettings, fs].sort();
    this.setState({ activeDefaultSettings: selectedDefaults });
    setFieldValue('activeDefaultSettings', selectedDefaults);
  };

  removeDefaultSetting = (fs: FormSegments, setFieldValue: FormikHelpers<any>['setFieldValue']) => {
    const { activeDefaultSettings } = this.state;
    const selectedDefaults = activeDefaultSettings.filter(ds => ds !== fs);
    this.setState({ activeDefaultSettings: selectedDefaults });
    setFieldValue('activeDefaultSettings', selectedDefaults);
  };

  resetAdvancedOptions = (
    fileTypeId: number,
    fileTypeName: string,
    setFieldValue: FormikHelpers<any>['setFieldValue']
  ) => {
    const advancedExportOptions = this.getExportFileType(fileTypeId)?.export_options.filter(
      option => option.advanced === 1
    );

    advancedExportOptions?.forEach(option => {
      if (option.format_type_id === 1 || option.format_type_id === 2)
        setFieldValue(
          `exportOptions[${fileTypeName}].${option.code}`,
          option.default_value && Number(option.default_value)
        );
      if (option.format_type_id === 3)
        setFieldValue(
          `exportOptions[${fileTypeName}].${option.code}`,
          option.default_value ? Number(option.default_value) : option.default_value || null
        );
      if ([4, 6].includes(option.format_type_id))
        setFieldValue(`exportOptions[${fileTypeName}].${option.code}`, option.default_value || []);
      if (option.format_type_id === 5)
        setFieldValue(`exportOptions[${fileTypeName}].${option.code}`, option.default_value);
    });
  };

  // check if default is enabled for plan
  // - when default switch is enabled use channel default
  // - when default switch is disabled use plan value or default value
  optionInitValues = (typeChanged?: boolean) => {
    const { fileExportOptions, defaultFile, plan = {} as Plan } = this.props;
    const defaultFileActive = !!(!plan.file && defaultFile);
    const useChDefaults = defaultFileActive || typeChanged;

    const planExportOptions: ChannelExportOptions[] = (plan.file && plan.file.export_options) || [];
    const initValues: Record<string, Record<string, number[] | null | string>> = {};

    fileExportOptions.forEach(fileType => {
      const optionValues: Record<string, any> = {};
      const filteredExportOptions = this.getAllowedOptions(fileType);
      filteredExportOptions?.forEach(option => {
        const planOption = planExportOptions?.find(op => op.option_id === option.id);
        const chFileOptions = defaultFile?.find(o => o.file_type_id === option.file_type_id);
        const channelDefOption = chFileOptions?.export_options.find(o => o.option_id === option.id);
        if (option.format_type_id === 1 || option.format_type_id === 2) {
          if (useChDefaults) {
            optionValues[option.code] = channelDefOption?.parameters
              ? channelDefOption?.parameters
              : option.default_value && Number(option.default_value);
          } else
            optionValues[option.code] =
              planOption?.parameters !== undefined
                ? planOption?.parameters
                : option.default_value && Number(option.default_value);
        }
        if (option.format_type_id === 3) {
          if (useChDefaults) {
            optionValues[option.code] = channelDefOption?.parameters
              ? channelDefOption?.parameters
              : (option.default_value && Number(option.default_value)) || null;
          } else
            optionValues[option.code] = plan.id
              ? planOption?.parameters || null
              : (option.default_value && Number(option.default_value)) || null;
        }
        if ([4, 6].includes(option.format_type_id)) {
          if (useChDefaults) {
            // channel defaults can include more price sheets/types than plans
            optionValues[option.code] =
              (channelDefOption?.parameters &&
                Array.isArray(channelDefOption.parameters) &&
                channelDefOption.parameters.filter(id =>
                  option.parameters.find(p => p.id === id)
                )) ||
              [];
          } else
            optionValues[option.code] = plan.id
              ? planOption?.parameters || []
              : option.default_value || [];
        }
        if (option.format_type_id === 5) {
          if (useChDefaults) {
            optionValues[option.code] =
              (channelDefOption?.parameters && channelDefOption.parameters) || '';
          } else
            optionValues[option.code] = plan.id
              ? planOption?.parameters || ''
              : option.default_value || '';
        }
      });
      initValues[fileType.name] = optionValues;
    });

    return initValues;
  };

  getAdvancedOptionsEnabled = (fileTypeId: number, typeChanged?: boolean) => {
    const { fileExportOptions, plan, defaultFile } = this.props;
    const defaultFileActive = !!(!plan.file && defaultFile);
    const chOptions = defaultFile?.find(o => o.file_type_id === fileTypeId)?.export_options;

    const exportOptions = fileExportOptions.find(
      fileTypeOption => fileTypeOption.id === fileTypeId || fileTypeOption.id === 1
    );
    const advancedExportOptions = this.getAllowedOptions(exportOptions).filter(
      o => o.advanced === 1
    );
    const planExportOptions =
      typeChanged && fileTypeId !== plan.file_type_id
        ? chOptions || []
        : (plan.file && plan.file.export_options) || (defaultFileActive && chOptions) || [];

    const value = advancedExportOptions?.find(option => {
      const pOption = planExportOptions.find(op => op.option_id === option.id);
      if ((option.format_type_id === 1 || option.format_type_id === 2) && pOption) {
        const defaultValue = option?.default_value
          ? Number(option.default_value)
          : option.default_value;
        if (typeof defaultValue !== typeof pOption.parameters) return true;
        if (
          typeof defaultValue === typeof pOption.parameters &&
          pOption.parameters !== defaultValue
        )
          return true;
      }
      if (option.format_type_id === 3)
        return pOption && Number(option.default_value) !== Number(pOption);
      if ([4, 5, 6].includes(option.format_type_id))
        return Array.isArray(pOption?.parameters) && pOption!.parameters.length > 0;
    });

    return !!value;
  };

  render() {
    const {
      t,
      plan = {} as Plan,
      deliveryFrequencies,
      deliveryTypes,
      ftpDirectoryTypes,
      protocolTypes,
      fileTypes,
      defaultFtp = {},
      defaultTemplateId,
      defaultSchedule = {},
      fileNameTemplates,
      defaultFileNameTemplate,
    } = this.props;
    const { activeDefaultSettings, activeWhitelabel, defaultPlanDisabled } = this.state;
    const defaultFileIsActive = activeDefaultSettings.includes(FormSegments.FILE);
    const defaultDeliveryIsActive = activeDefaultSettings.includes(FormSegments.DELIVERY);
    const defaultScheduleIsActive = activeDefaultSettings.includes(FormSegments.SCHEDULE);

    const { ftp = {} as FTP, file = {} as FileOptions, schedule = {} as Schedule } = plan;
    const segmentIds = plan.segment_ids || [];
    const selectedSegmentIds = segmentIds;
    const timeZoneOffset = `${moment.parseZone(moment().format()).format('z')} ${moment().format(
      'Z'
    )} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    const getInitialValues = () => {
      return {
        name: plan.name || '',
        filterId: (plan.filter_ids && plan.filter_ids[0]) || undefined,
        fileTypeId: plan.file_type_id || 1,
        fileTypeName: this.getFileName(plan.file_type_id || 1),
        withoutPrices: plan.without_prices || 0,
        priceSheetIds: plan.price_sheet_ids || [],
        priceTypeIds: plan.price_type_ids || [],
        exportTemplateId: Number(plan.export_template_id) || undefined,
        fullExportTemplateId: Number(plan.full_export_template_id) || undefined,
        baseVehicle: plan.use_base_vehicle || 0,
        individualYears: plan.individual_years || 0,
        selectedSegmentIds,
        whitelabelBrandCode: plan.whitelabel_brand_code || '',
        whitelabelCustomFieldId: plan.whitelabel_custom_field_id || 0,
        delivery: {
          deliveryTypeId: ftp.delivery_type_id || defaultFtp.delivery_type_id || 1,
          ftpDirectoryTypeId: ftp.ftp_directory_type_id || defaultFtp.ftp_directory_type_id,
          ftpModeId: ftp.ftp_mode_id || defaultFtp.ftp_mode_id,
          protocolTypeId: ftp.protocol_type_id || defaultFtp.protocol_type_id,
          ftpEncryptionTypeId: ftp.ftp_encryption_type_id || defaultFtp.ftp_encryption_type_id,
          customDirectory: ftp.custom_directory || defaultFtp.custom_directory || '',
          server: ftp.server || defaultFtp.server || '',
          user: ftp.user_name || defaultFtp.user_name || '',
          password: ftp.password || defaultFtp.password || '',
          port: ftp.port || defaultFtp.port || 21,
          email: ftp.email || defaultFtp.email || '',
        },
        fileNameTemplateId: file.file_name_template_id || defaultTemplateId,
        deliveryFrequencyId:
          schedule.delivery_frequency_id || defaultSchedule.delivery_frequency_id,
        deliveryDay: schedule.delivery_day || defaultSchedule.delivery_day,
        deliveryDate: schedule.delivery_date || defaultSchedule.delivery_date || 1,
        deliveryTime:
          schedule.delivery_time ||
          defaultSchedule.delivery_time ||
          moment.utc(moment().startOf('day')).format('HH:mm'),
        activeDefaultSettings: this.getPlanDefaultSegments(),
        exportOptions: this.optionInitValues(),
        advancedOptionsEnabled: this.getAdvancedOptionsEnabled(plan.file_type_id || 1),
      };
    };

    return (
      <Formik
        initialValues={getInitialValues()}
        validationSchema={this.getValidationSchema()}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          this.handlePlanChange(values)
            .then(result => {
              setSubmitting(false);
              resetForm({ values });
              this.props.selectPlan(result.value.data.id.toString());
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ handleSubmit, isSubmitting, handleChange, setFieldValue, values, dirty }) => (
          <Form className="channel__plan-form h-full flex flex-col" layout="vertical">
            <ChannelFormDirtyHandler />
            <FormErrorFocus />
            <div className="flex-1 overflow-auto pr-5">
              {!!plan.default && (
                <Button
                  onClick={() => this.handlePlanDelete(plan.id)}
                  className="mb-3"
                  icon={<UnlockOutlined />}
                  danger
                  ghost
                >
                  {t('channel:deactivateDefaultPlan')}
                </Button>
              )}

              <Row gutter={20}>
                <Col span={12}>
                  <FormInput
                    name="name"
                    label={t('channel:plan')}
                    placeholder={t('channel:planName')}
                    required
                    disabled={defaultPlanDisabled}
                  />
                </Col>
                <Col span={12}>
                  {this.props.showWhitelabel && (
                    <div className="flex">
                      <Col span={8} className="channel__plan-whitelabel">
                        <FormInput
                          name="whitelabelBrandCode"
                          label={
                            <span className="channel__whitelabel-title-text whitespace-nowrap">
                              {t('channel:whitelabel')}
                              <HelpCenter id="whitelabel_info" />
                            </span>
                          }
                          placeholder={t('channel:brandCode')}
                          disabled={!activeWhitelabel || defaultPlanDisabled}
                        />
                      </Col>
                      <Col span={16} className="channel__plan-whitelabel">
                        <FormSelect
                          name="whitelabelCustomFieldId"
                          values={[
                            { id: 0, name: `(${t('channel:useOriginalPartNumber')})` },
                            ...this.props.brandCustomFields,
                          ]}
                          label={
                            <div className="flex flex-1 justify-end">
                              <div className="channel__whitelabel-title-text">
                                {activeWhitelabel ? t('common:enabled') : t('common:disabled')}
                              </div>
                              <Switch
                                checked={this.state.activeWhitelabel}
                                onChange={(checked: boolean) => {
                                  this.setState({ activeWhitelabel: checked }, () => {
                                    if (!checked) {
                                      setFieldValue('whitelabelCustomFieldId', 0);
                                      setFieldValue('whitelabelBrandCode', '');
                                    }
                                  });
                                }}
                                size="small"
                              />
                            </div>
                          }
                          placeholder={t('channel:partNumber')}
                          disabled={!activeWhitelabel || defaultPlanDisabled}
                        />
                      </Col>
                    </div>
                  )}
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={7}>
                  <FormSelect
                    name="fileTypeId"
                    values={fileTypes}
                    label={t('channel:fileType')}
                    required
                    disabled={defaultPlanDisabled}
                    onChange={async (value: SelectValue) => {
                      setFieldValue('fileTypeId', value);
                      setFieldValue('fileTypeName', this.getFileName(Number(value)));
                      await this.setState({ currentFileTypeId: Number(value) });
                      setFieldValue('exportOptions', this.optionInitValues(true));
                      setFieldValue(
                        'advancedOptionsEnabled',
                        this.getAdvancedOptionsEnabled(Number(value), true)
                      );
                    }}
                    clearContainer
                  />
                </Col>
                <Col span={7}>
                  <FormSelect
                    name="filterId"
                    values={this.getFilters()}
                    label={t('channel:filter')}
                    placeholder={t('channel:selectFilter')}
                    allowClear
                    disabled={defaultPlanDisabled}
                  />
                </Col>
              </Row>
              <div>
                <SwitchDivider
                  title={t('channel:fileSettings')}
                  switchTag={t('channel:useDefault')}
                  disabled={!this.props.defaultFile || defaultPlanDisabled}
                  checked={values.activeDefaultSettings.includes(FormSegments.FILE)}
                  handleChange={(checked: boolean) => {
                    checked
                      ? this.addDefaultSetting(FormSegments.FILE, setFieldValue)
                      : this.removeDefaultSetting(FormSegments.FILE, setFieldValue);
                  }}
                />
                {!defaultFileIsActive && (
                  <React.Fragment>
                    <div className="flex">
                      <FormSelect
                        className="flex-1"
                        name="fileNameTemplateId"
                        values={fileNameTemplates}
                        label={t('channel:namingConvention')}
                        disabled={defaultPlanDisabled}
                        placeholder={`${t('common:default')}: ${defaultFileNameTemplate.config}`}
                        allowClear
                      />
                      <Button
                        className="channel__naming-convention-button self-end"
                        onClick={() => this.props.openNamingConventionDrawer()}
                      >
                        {t('channel:editNamingConvention')}
                      </Button>
                    </div>
                    <div className="pl-3 pb-3 text-gray-600 text-xs">
                      {this.getNamingConventionPreviewText(values.fileNameTemplateId!)}
                    </div>
                    {!defaultPlanDisabled && (
                      <ExportFormatOptions
                        handleAdvancedOptions={advancedOptionsEnabled => {
                          setFieldValue('advancedOptionsEnabled', advancedOptionsEnabled);
                          if (!advancedOptionsEnabled)
                            this.resetAdvancedOptions(
                              values.fileTypeId,
                              values.fileTypeName,
                              setFieldValue
                            );
                        }}
                        segment="plan"
                        fileTypeName={values.fileTypeName}
                        fileTypeId={values.fileTypeId}
                        exportOptions={
                          this.getExportFileType(values.fileTypeId!)?.export_options || []
                        }
                        canManageAdvancedExportOptions={this.props.canManageAdvancedExportOptions}
                      />
                    )}
                  </React.Fragment>
                )}
              </div>

              <div>
                <SwitchDivider
                  title={t('channel:scheduleSettings')}
                  switchTag={t('channel:useDefault')}
                  disabled={!this.props.defaultSchedule || defaultPlanDisabled}
                  checked={values.activeDefaultSettings.includes(FormSegments.SCHEDULE)}
                  handleChange={(checked: boolean) =>
                    checked
                      ? this.addDefaultSetting(FormSegments.SCHEDULE, setFieldValue)
                      : this.removeDefaultSetting(FormSegments.SCHEDULE, setFieldValue)
                  }
                />
                {!defaultScheduleIsActive && (
                  <Row gutter={20}>
                    <Col span={9}>
                      <FormRadio
                        name="deliveryFrequencyId"
                        values={deliveryFrequencies.map(freq => ({
                          ...freq,
                          disabled:
                            Number(values.exportOptions[values.fileTypeName!].submission_type) ===
                              1 && freq.id === 1, // submission_type_id
                        }))}
                        label={t('channel:deliveryFrequency')}
                        handleChange={(e: any) => {
                          // update delivery method when "use default" switch is disabled
                          if (
                            !defaultDeliveryIsActive &&
                            e.target.value !== 4 &&
                            values.delivery.deliveryTypeId === 3
                          ) {
                            setFieldValue('delivery.deliveryTypeId', 1);
                          }
                          handleChange(e);
                        }}
                        disabled={defaultScheduleIsActive}
                        required
                      />
                    </Col>
                    {values.deliveryFrequencyId === 2 && (
                      <Col span={3}>
                        <FormSelect
                          name="deliveryDay"
                          values={days}
                          label={t('channel:deliveryDay')}
                          disabled={defaultScheduleIsActive}
                          required
                        />
                      </Col>
                    )}
                    {values.deliveryFrequencyId === 3 && (
                      <Col span={3}>
                        <FormSelect
                          name="deliveryDate"
                          values={[...new Array(28)].map((_, i) => ({ id: i + 1, name: i + 1 }))}
                          label={t('channel:deliveryDay')}
                          disabled={defaultScheduleIsActive}
                          required
                        />
                      </Col>
                    )}
                    {values.deliveryFrequencyId !== 4 && (
                      <Col span={12}>
                        <div className="w-auto mr-3 inline-block">
                          <TimeInput
                            name="deliveryTime"
                            label={t('channel:deliveryTime')}
                            setFieldValue={setFieldValue}
                            disabled={defaultScheduleIsActive}
                          />
                        </div>
                        <div className="inline-block align-top ml-5">
                          <div className="ant-text-black mb-3">
                            {`${t('channel:localTime')}: (${timeZoneOffset})`}
                          </div>
                          {values.deliveryFrequencyId === 1 && (
                            <div>
                              {moment.utc(values.deliveryTime, 'HH:mm').local().format('hh:mm a')}
                            </div>
                          )}
                          {values.deliveryFrequencyId === 2 && (
                            <div>
                              {moment
                                .utc(values.deliveryTime, 'HH:mm')
                                .day(values.deliveryDay)
                                .local()
                                .format('dddd hh:mm a')}
                            </div>
                          )}
                          {values.deliveryFrequencyId === 3 && (
                            <div>
                              {moment
                                .utc(values.deliveryTime, 'HH:mm')
                                .date(values.deliveryDate)
                                .local()
                                .format('[Day:] D - hh:mm a')}
                            </div>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
              </div>

              <div>
                <SwitchDivider
                  title={t('channel:deliverySettings')}
                  switchTag={t('channel:useDefault')}
                  disabled={!this.props.defaultFtp || defaultPlanDisabled}
                  checked={values.activeDefaultSettings.includes(FormSegments.DELIVERY)}
                  handleChange={(checked: boolean) =>
                    checked
                      ? this.addDefaultSetting(FormSegments.DELIVERY, setFieldValue)
                      : this.removeDefaultSetting(FormSegments.DELIVERY, setFieldValue)
                  }
                />
                {!defaultDeliveryIsActive && (
                  <DeliveryFormPart
                    disabled={defaultDeliveryIsActive}
                    deliveryTypes={deliveryTypes}
                    ftpDirectoryTypes={ftpDirectoryTypes}
                    protocolTypes={protocolTypes}
                    // ftpModes={ftpModes}
                    // encryptionTypes={encryptionTypes}
                    values={values}
                  />
                )}
              </div>
            </div>

            <div className="drawer-submit__bottom flex-col items-end">
              <div>
                <Button
                  onClick={() => this.props.onClose(true)}
                  className="drawer-submit__bottom-cancel"
                >
                  {t('common:cancel')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleSubmit()}
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting}
                >
                  {this.props.create ? t('common:create') : t('common:save')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  deliveryFrequencies: state.resources.data.channel.delivery_frequencies,
  deliveryTypes: state.resources.data.channel.delivery_types,
  ftpModes: state.resources.data.channel.ftp_modes,
  ftpDirectoryTypes: state.resources.data.channel.ftp_directory_types,
  protocolTypes: state.resources.data.channel.protocol_types,
  encryptionTypes: state.resources.data.channel.ftp_encryption_types,
  fileNameConfigOptions: state.resources.data.global.file_name_config_options,
  defaultFileNameTemplate: state.resources.data.global.file_name_templates[0],
  brandCustomFields: state.brand.customFields.customFields,
  fileNameTemplates: state.channel.channels.fileNameTemplates,
  fileExportOptions: state.brand.brandExport.fileExportOptions,
  filters: getAllCustomFilters(state),
  canManageAdvancedExportOptions: hasPermission(
    state.user.user,
    'can_manage_advanced_export_options'
  ),
});

export default connect(mapStateToProps)(withContainerWrapper(withTranslation()(EditChannelPlan)));
