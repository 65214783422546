import React, { useCallback } from 'react';
import { CustomFloatingFilterProps } from 'ag-grid-react';

const YearRangeFloatingFilter: React.FC<CustomFloatingFilterProps> = ({
  model,
  showParentFilter,
}) => {
  const getModelAsString = useCallback(() => {
    const selectedYears = model?.values;
    return model == null ? '' : `(${selectedYears.length}) ${selectedYears.join(',')}`;
  }, [model]);

  return (
    <div className="ag-floating-filter-input ag-set-floating-filter-input" role="presentation">
      <div
        className="ag-labeled ag-label-align-left ag-text-field ag-input-field ag-disabled"
        role="presentation"
      >
        <div
          className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
          onClick={() => showParentFilter()}
        >
          <input
            className="ag-input-field-input ag-text-field-input"
            value={getModelAsString()}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default YearRangeFloatingFilter;
